import React, { useState } from 'react';
import './Tooltip.css'; // Create corresponding CSS for styling

const Tooltip = ({ content, children }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div
            className="tooltip-container"
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {children}
            {visible && (
                <div className="tooltip-content">
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;