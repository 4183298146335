import React, { useState, useRef, useEffect } from 'react';
import { Rnd } from 'react-rnd';

const ApparelPreview = ({ uploadedImage, onUpdateDimensions, maskImage }) => {
  const [saved, setSave] = useState(false);
  const imgRef = useRef(null);
  const containerRef = useRef(null);

  // Fixed container dimensions suitable for laptop screens
  const CONTAINER_WIDTH = 600; // in pixels
  const CONTAINER_HEIGHT = 600; // in pixels

  const [maskDimensions, setMaskDimensions] = useState({
    width: 200,
    height: 200,
    x: (CONTAINER_WIDTH - 200) / 2,
    y: (CONTAINER_HEIGHT - 200) / 2,
  });

  // Percentage states
  const [topPercent, setTopPercent] = useState((maskDimensions.y / CONTAINER_HEIGHT) * 100);
  const [leftPercent, setLeftPercent] = useState((maskDimensions.x / CONTAINER_WIDTH) * 100);
  const [widthPercent, setWidthPercent] = useState((maskDimensions.width / CONTAINER_WIDTH) * 100);
  const [heightPercent, setHeightPercent] = useState((maskDimensions.height / CONTAINER_HEIGHT) * 100);

  // Initialize mask dimensions to center upon image upload
  useEffect(() => {
    if (uploadedImage) {
      setMaskDimensions((prev) => ({
        ...prev,
        x: (CONTAINER_WIDTH - prev.width) / 2,
        y: (CONTAINER_HEIGHT - prev.height) / 2,
      }));
    }
  }, [uploadedImage]);

  // Synchronize percentage states when maskDimensions change
  useEffect(() => {
    setTopPercent((maskDimensions.y / CONTAINER_HEIGHT) * 100);
    setLeftPercent((maskDimensions.x / CONTAINER_WIDTH) * 100);
    setWidthPercent((maskDimensions.width / CONTAINER_WIDTH) * 100);
    setHeightPercent((maskDimensions.height / CONTAINER_HEIGHT) * 100);
  }, [maskDimensions, CONTAINER_WIDTH, CONTAINER_HEIGHT]);

  // Handle drag event
  const handleDrag = (e, d) => {
    setMaskDimensions((prev) => ({
      ...prev,
      x: d.x,
      y: d.y,
    }));
    console.log('Top:', d.y);
    console.log('Left:', d.x);
    console.log('Width:', maskDimensions.width);
    console.log('Height:', maskDimensions.height);
  };

  // Handle resize stop event
  const handleResizeStop = (e, direction, ref, delta, position) => {
    setMaskDimensions({
      width: ref.offsetWidth,
      height: ref.offsetHeight,
      x: position.x,
      y: position.y,
    });
    console.log('Top:', position.y);
    console.log('Left:', position.x);
    console.log('Height:', ref.offsetHeight);
    console.log('Width:', ref.offsetWidth);
  };

  // Handle percentage input changes
  const handleTopPercentChange = (e) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) value = 0;
    value = Math.max(0, Math.min(value, 100 - heightPercent)); // Ensure top + height <= 100
    setTopPercent(value);
    const newY = (value / 100) * CONTAINER_HEIGHT;
    setMaskDimensions((prev) => ({
      ...prev,
      y: newY,
    }));
  };

  const handleLeftPercentChange = (e) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) value = 0;
    value = Math.max(0, Math.min(value, 100 - widthPercent)); // Ensure left + width <= 100
    setLeftPercent(value);
    const newX = (value / 100) * CONTAINER_WIDTH;
    setMaskDimensions((prev) => ({
      ...prev,
      x: newX,
    }));
  };

  const handleWidthPercentChange = (e) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) value = 0;
    value = Math.max(0, Math.min(value, 100 - leftPercent)); // Ensure left + width <= 100
    setWidthPercent(value);
    const newWidth = (value / 100) * CONTAINER_WIDTH;
    setMaskDimensions((prev) => ({
      ...prev,
      width: newWidth,
    }));
  };

  const handleHeightPercentChange = (e) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) value = 0;
    value = Math.max(0, Math.min(value, 100 - topPercent)); // Ensure top + height <= 100
    setHeightPercent(value);
    const newHeight = (value / 100) * CONTAINER_HEIGHT;
    setMaskDimensions((prev) => ({
      ...prev,
      height: newHeight,
    }));
  };

  // Handle Save button click
  const dashboardRedirect = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { x, y, width, height } = maskDimensions;

    // Calculate percentages relative to container
    const top = (y / CONTAINER_HEIGHT) * 100;
    const left = (x / CONTAINER_WIDTH) * 100;
    const widthPercentFinal = (width / CONTAINER_WIDTH) * 100;
    const heightPercentFinal = (height / CONTAINER_HEIGHT) * 100;

    onUpdateDimensions({
      top,
      left,
      height: heightPercentFinal,
      width: widthPercentFinal,
    });
    setSave(true);
    console.log('Dimensions saved:', { top, left, height: heightPercentFinal, width: widthPercentFinal });
  };

  return (
    <div style={{ marginTop: '1%', textAlign: 'center' }}>
      <span>Please align the mask over your product image.</span>
      <div
        ref={containerRef}
        style={{
          width: `${CONTAINER_WIDTH}px`,
          height: `${CONTAINER_HEIGHT}px`,
          margin: '20px auto',
          position: 'relative',
          border: '1px solid #ccc', // Light border to visualize the container
          overflow: 'hidden', // Prevent mask from overflowing
        }}
      >
        {/* Product Image */}
        {uploadedImage && (
          <img
            ref={imgRef}
            src={uploadedImage}
            alt="Product"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Maintain aspect ratio
              userSelect: 'none', // Prevent text selection
              pointerEvents: 'none', // Allow mask to capture mouse events
            }}
            draggable={false}
          />
        )}

        {/* Draggable and Resizable Mask */}
        {maskImage && (
          <Rnd
            size={{ width: maskDimensions.width, height: maskDimensions.height }}
            position={{ x: maskDimensions.x, y: maskDimensions.y }}
            onDrag={handleDrag}
            onResizeStop={handleResizeStop}
            bounds="parent"
            lockAspectRatio={true} // Maintain square aspect ratio
            style={{
              border: '2px solid red',
              pointerEvents: 'auto',
              boxSizing: 'border-box',
            }}
            enableResizing={{
              topRight: true,
              bottomRight: true,
              bottomLeft: true,
              topLeft: true,
            }}
          >
            <img
              src={maskImage}
              alt="Mask"
              style={{
                width: '100%',
                height: '100%',
                cursor: 'move', // Indicate draggable area
                opacity: 0.5,
                userSelect: 'none',
              }}
              draggable={false}
            />
          </Rnd>
        )}
      </div>

      {/* Percentage Inputs */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <div>
          <label htmlFor="topPercent">Top (%): </label>
          <input
            id="topPercent"
            type="number"
            value={topPercent.toFixed(1)}
            onChange={handleTopPercentChange}
            min="0"
            max={(100 - heightPercent).toFixed(1)}
          />
        </div>
        <div>
          <label htmlFor="leftPercent">Left (%): </label>
          <input
            id="leftPercent"
            type="number"
            value={leftPercent.toFixed(1)}
            onChange={handleLeftPercentChange}
            min="0"
            max={(100 - widthPercent).toFixed(1)}
          />
        </div>
        <div>
          <label htmlFor="widthPercent">Width (%): </label>
          <input
            id="widthPercent"
            type="number"
            value={widthPercent.toFixed(1)}
            onChange={handleWidthPercentChange}
            min="0"
            max={(100 - leftPercent).toFixed(1)}
          />
        </div>
        <div>
          <label htmlFor="heightPercent">Height (%): </label>
          <input
            id="heightPercent"
            type="number"
            value={heightPercent.toFixed(1)}
            onChange={handleHeightPercentChange}
            min="0"
            max={(100 - topPercent).toFixed(1)}
          />
        </div>
      </div>

      {/* Save Button */}
      <button
        className="btn-mbl"
        style={{
          backgroundColor: 'rgb(128, 0, 0)',
          color: 'white',
          borderRadius: '5px',
          marginTop: '10px',
          padding: '10px 20px',
          border: 'none',
          cursor: 'pointer',
        }}
        onClick={dashboardRedirect}
      >
        Save
      </button>
      {saved && <span style={{ marginLeft: '10px', color: 'green' }}>Dimensions Saved</span>}
    </div>
  );
};

export default ApparelPreview;