import { React, useState } from 'react';
import Redesign from './images/redesign.png'
import './Pagination.css'


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrev = () => {
      if (currentPage > 1) {
        onPageChange(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) {
        onPageChange(currentPage + 1);
      }
    };
  
    return (<>
      {!totalPages <= 0 &&
      <div className="pagination">
        <button onClick={handlePrev} disabled={currentPage === 1}>
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button onClick={handleNext} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>}
      </>
    );
  };

const Preloader = () => {
    return (
        <div style={{height:`100px`,width:`100px`}} className="preloader"></div>
    );
};

const TickIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" width="20" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
    </svg>
);

const SenttoPrintify = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M19 8h-1V3H6v5H5c-1.11 0-2 .9-2 2v7h4v4h10v-4h4v-7c0-1.1-.9-2-2-2zm-5 11H10v-5h4v5zm3-11H7V5h10v3z"/>
    </svg>
);

const CancelIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
);

const ResettoPending = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12" y2="8"></line>
        <line x1="8" y1="12" x2="8" y2="12"></line>
        <line x1="16" y1="12" x2="16" y2="12"></line>
    </svg>
);

const DeleteBtn = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
        <path fill="none" d="M0 0h24v24H0z"/>
        <path fill="currentColor" d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
    </svg>
);

const OrderListView = ({
  filteredOrders,
  toggledUrls,
  currentImageIndex,
  visibleSection,
  handleImageChange,
  RedesignImg,
  handleSelectOrder,
  handleResetStatus,
  handleVerify,
  handleModal,
  handleDelete,
  handlePrint,
  toggleSection,
  handleEditDesign,
  isOrderSelected,
  statuschange,
  changedOrder
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 30; // Number of orders per page
  
    // Calculate total pages
    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
  
    // Get orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  return (
    <>
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">select</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prompt</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Preview</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email ID</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {currentOrders.map(order => (
          <tr key={order.order_id}>
            <td style={{textAlign: `center`}}>
            {order.status === 'pending' && (
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={isOrderSelected(order.order_id)}
                  onChange={(e) => handleSelectOrder(order.user_id, order.order_id, order.shipping_info.email, e.target.checked)}
                />
                <span className="checkmark"></span>
                <span className="select-order"></span>
              </label>
            )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900">
              <div className="prompt-item tooltip" style={{ width: '100%' }}>
                <h3 style={{width:`100px`, whiteSpace: `nowrap`, overflow: `hidden`, textOverflow: `ellipsis`}}>{order.order_id}</h3>
                <span className="tooltiptext" style={{width: `auto`}}>{order.order_id}</span>
              </div>
              </div>
            </td>
            <td>
                {order.item.map((item, index) => (
                <div key={index} className="prompt-item tooltip" style={{ width: '100%' }}>
                    <h3 style={{ width:`200px`, whiteSpace: `nowrap`, overflow: `hidden`, textOverflow: `ellipsis` }}>{item.prompt} </h3>
                    <span className="tooltiptext">{item.prompt}</span>
                </div>
                ))}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className={``}>{order.status}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.item.map((item, index) => (
                <>
                  {(item.toggled === false || toggledUrls[order.order_id]) ? (
                    <img
                      src={item.toggled === false ? item.img_url : toggledUrls[order.order_id]}
                      alt="Main design"
                      className="design-img block w-full object-cover"
                      style={{
                        display: order.autogenerated ? 'block' : currentImageIndex[order.order_id] === index ? 'block' : 'none',
                        borderRadius: '10px',
                        height:'100px',
                        width:'100px'
                      }}
                    />
                  ) : (
                    <img
                      src={item.toggled === false ? item.img_url : toggledUrls[order.order_id]}
                      alt="Main design loader"
                      className="design-img block w-full object-cover"
                      style={{ display: 'none' }}
                    />
                  )}
                  {item.toggled && !toggledUrls[order.order_id] && <Preloader />}
                </>
              ))}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.autogenerated && order.status === 'pending' && (
                <button className="redesign-img-btn" onClick={() => RedesignImg(order)}>
                  <img src={Redesign} alt="regenerate image on titan" />
                  <span className="tooltiptext">Regenerate Image</span>
                </button>
              )}
              <div className="Button-container flex justify-evenly">
                <div className="tooltip">
                  <button
                    onClick={() => handleResetStatus(order.user_id, order.order_id, order.shipping_info.email)}
                    className={`m-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-8 rounded transition duration-150 ease-in-out text-sm ${(order.status === 'verified') ? '' : 'hidden'}`}
                    disabled={statuschange && changedOrder === order.order_id}>
                    <ResettoPending />
                  </button>
                  <span className="tooltiptext">Reset to Pending</span>
                </div>
                <div className="tooltip">
                  <button
                    onClick={() => handleVerify(order.user_id, order.order_id, order.shipping_info.email)}
                    className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-green-500 hover:bg-green-700 text-white ${(order.status === 'pending') ? '' : 'hidden'}`}
                    disabled={statuschange && changedOrder === order.order_id}>
                    <TickIcon />
                  </button>
                  <span className="tooltiptext">Verify Order</span>
                </div>
                <div className="tooltip">
                  <button
                    onClick={() => handleModal(order.user_id, order.order_id, order.shipping_info.email)}
                    className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-red-500 hover:bg-red-700 text-white ${(order.status === 'pending') ? '' : 'hidden'}`}>
                    <CancelIcon />
                  </button>
                  <span className="tooltiptext">Cancel Order</span>
                </div>
                {order.autogenerated && order.status === 'pending' && (
                  <div className="tooltip">
                    <button
                      onClick={() => handleDelete(order.user_id, order.order_id, order.shipping_info.email)}
                      className="m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-red-500 hover:bg-red-700 text-white">
                      <DeleteBtn />
                    </button>
                    <span className="tooltiptext">Delete Order</span>
                  </div>
                )}
                {order.status === 'verified' && (
                  <div className="tooltip">
                    <button
                      onClick={() => handlePrint(order)}
                      className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-green-500 hover:bg-green-700 text-white ${(order.status === 'verified') ? '' : 'hidden'}`}>
                      <SenttoPrintify />
                    </button>
                    <span className="tooltiptext">Send to Printify</span>
                  </div>
                )}
              </div>
            </td>
            <td>
            <p>{order.shipping_info.email}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </>
  );
};

export default OrderListView;
