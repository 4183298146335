import { React, useState } from 'react';
import Redesign from './images/redesign.png'
import './Pagination.css'
import copyIcon from './images/copy-icon.png'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrev = () => {
      if (currentPage > 1) {
        onPageChange(currentPage - 1);
      }
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) {
        onPageChange(currentPage + 1);
      }
    };
  
    return (<>
      {!totalPages <= 0 && <div className="pagination">
        <button onClick={handlePrev} disabled={currentPage === 1}>
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button onClick={handleNext} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>}
      </>
    );
  };

const Preloader = () => {
    return (
        <div className="preloader"></div>
    );
};

  const TickIcon = () => {
      return (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" width="20" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
      );
  };
  const SenttoPrintify = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 8h-1V3H6v5H5c-1.11 0-2 .9-2 2v7h4v4h10v-4h4v-7c0-1.1-.9-2-2-2zm-5 11H10v-5h4v5zm3-11H7V5h10v3z"/>
    </svg>
    
    )
  }
  const CancelIcon = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
</svg>

    );
  }
  const ResettoPending = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"  fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <circle cx="12" cy="12" r="10"></circle>
  <line x1="12" y1="16" x2="12" y2="12"></line>
  <line x1="12" y1="8" x2="12" y2="8"></line>
  <line x1="8" y1="12" x2="8" y2="12"></line>
  <line x1="16" y1="12" x2="16" y2="12"></line>
</svg>

    )
  }
  const DeleteBtn = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path fill="currentColor" d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
    </svg>    
  )
  }

const OrderList = ({
  filteredOrders,
  toggledUrls,
  currentImageIndex,
  visibleSection,
  handleImageChange,
  RedesignImg,
  handleSelectOrder,
  handleResetStatus,
  handleVerify,
  handleModal,
  handleDelete,
  handlePrint,
  toggleSection,
  handleEditDesign,
  isOrderSelected,
  statuschange,
  changedOrder
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 32; // Number of orders per page
  
    // Calculate total pages
    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
  
    // Get orders for the current page
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  return (
    <>
    {/* <div className={`grid ${(window.innerWidth < 544) ? `grid-cols-1` : ((window.innerWidth >= 768) && (window.innerWidth <= 1024)) ? `grid-cols-3` : `grid-cols-4`}  gap-4 `}> */}
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {currentOrders.map(order => (
        <div className={`main-div bg-white main-div-grid order-div`}>
          <div className="image-container">
            <div className={`status-code-${order.status}`}>{order.status}</div>
            {order.item.map((item, index) => (
              <>
                {(item.toggled === false || (typeof item.toggled == 'string' && item.toggled.includes('http'))) ? (
                  <img
                    src={item.toggled === false ? item.img_url : item.toggled}
                    alt="Main design"
                    className="design-img-preview block w-full object-cover"
                    style={{
                      display: order.autogenerated ? 'block' : currentImageIndex[order.order_id] === index ? 'block' : 'none',
                      borderRadius: '10px 10px 0 0'
                    }}
                  />
                ) : (
                  <img
                    src={item.toggled === false ? item.img_url : item.toggled}
                    alt="Main design loader"
                    className="design-img-preview block w-full object-cover"
                    style={{ display: 'none' }}
                  />
                )}
                {(item.toggled && (typeof item.toggled == 'string' && !item.toggled.includes('http'))) && <Preloader />}
              </>
            ))}
            <button onClick={() => handleImageChange(order.order_id, order.item.length, true)}
              className={`next-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded transition duration-150 ease-in-out text-sm ${(order.item.length > 1) ? `flex` : `hidden`}`}>
              <svg viewBox="0 0 24 24">
                <path d="M8.59 16.58L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
              </svg>
            </button>
          </div>
          <div className="order-status" style={{ position: 'relative' }}>
            {order.autogenerated && order.status == 'pending' && <button className={`redesign-img-btn`} onClick={() => RedesignImg(order)}>
              <img src={Redesign} alt='regenerate image on titan' />
              <span className="tooltiptext">Regenerate Image</span>
            </button>}
            {order.status === 'pending' && (
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={isOrderSelected(order.order_id)}
                  onChange={(e) => handleSelectOrder(order.user_id, order.order_id, order.shipping_info.email, e.target.checked)}
                />
                <span className="checkmark"></span>
                <span className="select-order">select order</span>
              </label>
            )}
            {order.item.map((item, index) => (
              <>{!(item.thumbnail == "null") && <>
                <button onClick={() => toggleSection(order.order_id, 'preview')}
                  className="mt-3 bg-gray-200 hover:bg-gray-300 text-black py-1 px-3 rounded transition duration-150 ease-in-out text-sm w-full text-center">
                  Preview
                </button>
                {visibleSection[order.order_id]?.preview && order.item.map((item, index) => (
                  <>
                    <div className="preview" style={{ display: currentImageIndex[order.order_id] === index ? 'block' : 'none', }}>
                      {order.item.map((item, index) => (
                        <img
                          src={item.thumbnail}
                          alt="Thumbnail"
                          className="design-img-preview block w-full object-cover"
                          style={{ display: currentImageIndex[order.order_id] === index ? 'block' : 'none', }}
                        />
                      ))}
                    </div>
                    {order.autogenerated && <button onClick={() => { handleEditDesign(order.org_id, item.apparel, item.color, item.img_url, order) }}>Edit Design</button>}
                  </>
                ))}
              </>}</>
            ))}
            {order.item.map((item, index) => (
              <div key={index} className="prompt-item tooltip" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <h3 style={{ whiteSpace: `nowrap`, overflow: `hidden`, textOverflow: `ellipsis`, marginRight: '10px' }}>
                  <b>Prompt :</b> {item.prompt}
                </h3>
                <img
                  src={copyIcon} 
                  alt="Copy"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                  onClick={() => navigator.clipboard.writeText(item.prompt)} 
                  title="Copy prompt"
                />
                <span className="tooltiptext">{item.prompt}</span>
              </div>
            ))}
            <div className={`Button-container flex`} style={{ justifyContent: `space-evenly` }}>
              <div className="tooltip">
                <button
                  onClick={() => handleResetStatus(order.user_id, order.order_id, order.shipping_info.email)}
                  className={`m-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-8 rounded transition duration-150 ease-in-out text-sm
                          ${(order.status == 'verified') ? `` : `hidden`}
                          `}
                  disabled={statuschange && changedOrder === order.order_id}>
                  <ResettoPending />
                </button>
                <span className="tooltiptext">Reset to Pending</span>
              </div>
              <div className="tooltip">
                <button
                  onClick={() => handleVerify(order.user_id, order.order_id, order.shipping_info.email)}
                  className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-green-500 hover:bg-green-700 text-white
                        ${(order.status == 'pending') ? `` : `hidden`}
                        ${statuschange && changedOrder === order.order_id}
                        `}
                  disabled={statuschange && changedOrder === order.order_id}>
                  <TickIcon />
                </button>
                <span className="tooltiptext">Verify Order</span>
              </div>
              <div className="tooltip">
                <button
                  onClick={() => handleModal(order.user_id, order.order_id, order.shipping_info.email)}
                  className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-red-500 hover:bg-red-700 text-white
                    ${(order.status == 'pending' ? `` : `hidden`)}
                    `}>
                  <CancelIcon />
                </button>
                <span className="tooltiptext">Cancel Order</span>
              </div>
              {order.autogenerated && order.status == 'pending' &&
                <div className="tooltip">
                  <button
                    onClick={() => handleDelete(order.user_id, order.order_id, order.shipping_info.email)}
                    className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-red-500 hover:bg-red-700 text-white`}>
                    <DeleteBtn />
                  </button>
                  <span className="tooltiptext">Delete Order</span>
                </div>
              }
              <div className="tooltip">
                <button
                  onClick={() => handlePrint(order)}
                  className={`m-2 py-1 px-8 rounded transition duration-150 ease-in-out font-bold text-sm bg-green-500 hover:bg-green-700 text-white
                    ${(order.status == 'verified' ? `` : `hidden`)}
                    `}>
                  <SenttoPrintify />
                </button>
                <span className="tooltiptext">Send to Printful</span>
              </div>
            </div>
            <div className={`refund-div grid grid-cols-3 flex ${(order.status === 'cancelled' ? '' : 'hidden')}`} style={{ width: '100%' }}>
              <div style={{ display: 'contents', textAlign: 'center' }}>
                <span style={{ marginTop: 'auto', marginBottom: 'auto' }} className="Refund"><b>Refund : </b></span>
              </div>
              <div className="prompt-item" style={{ display: 'contents' }}>
                <div style={{ display: 'contents' }}>
                  <span style={{ whiteSpace: `nowrap`, overflow: `hidden`, textOverflow: `ellipsis`, textAlign: 'right', marginRight: `4px` }}>Refund Amount</span>
                  <span style={{ display: 'contents', textAlign: 'center', padding: `0`, borderRadius: `10px`, height: `100%` }}>{order.item.refund_amount}</span>
                </div>
              </div>
              <div style={{ display: 'contents', textAlign: 'center' }}>
                <button onClick={() => toggleSection(order.order_id, 'refund_reason')}
                  className="mt-3 bg-gray-200 hover:bg-gray-300 text-black py-1 px-3 rounded transition duration-150 ease-in-out text-sm w-full text-center"
                >
                  Reason
                </button>
              </div>
              {visibleSection[order.order_id]?.refund_reason && (
                <div className="prompt-item tooltip" style={{ width: '100%' }}>
                  <h3 style={{ whiteSpace: `nowrap`, overflow: `hidden`, textOverflow: `ellipsis` }}>
                    <b>Refund Reason :</b> {order.item.refund_reason}</h3>
                  <span className="tooltiptext">{order.item.refund_reason}</span>
                </div>
              )}
            </div>
            <button onClick={() => toggleSection(order.order_id, 'items')}
                    className="mt-3 bg-gray-200 hover:bg-gray-300 text-black py-1 px-3 rounded transition duration-150 ease-in-out text-sm w-full text-center">
              {visibleSection[order.order_id]?.items ? 'Hide Items' : 'Show Items'}
            </button>
            {visibleSection[order.order_id]?.items && order.item.map((item, index) => (
              <div key={index} className="item-details p-2 border-t mt-2 text-sm">
                <p><b>Apparel:</b> {item.apparel}</p>
                <p><b>Size:</b> {item.size}</p>
                <p><b>Color:</b> {item.color}</p>
                <p><b>Order ID:</b> {order.order_id}</p>
                <p><b>Prompt:</b> {item.prompt}</p>
                <p><b>Design ID:</b> {item.img_id}</p>
              </div>
            ))}
            <button onClick={() => toggleSection(order.order_id, 'shipping')}
                    className="mt-3 bg-gray-200 hover:bg-gray-300 text-black py-1 px-3 rounded transition duration-150 ease-in-out text-sm w-full text-center">
              {visibleSection[order.order_id]?.shipping ? 'Hide Shipping Info' : 'Show Shipping Info'}
            </button>
            {visibleSection[order.order_id]?.shipping && (
              <div className="shipping-info mt-2 p-2 border-t text-sm">
                <p>Name: {order.shipping_info.firstName} {order.shipping_info.lastName}</p>
                <p>Email: {order.shipping_info.email}</p>
                <p>Phone: {order.shipping_info.phone}</p>
                <p>Address: {order.shipping_info.streetAddress} {order.shipping_info.streetAddress2}</p>
                <p>City: {order.shipping_info.city}</p>
                <p>State: {order.shipping_info.stateProvince}</p>
                <p>Postal Code: {order.shipping_info.postalZipcode}</p>
                <p>Organisation Name: {order.org_name}</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
    <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
</>
  );
};

export default OrderList;
