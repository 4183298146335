import React, { useEffect, useState, useRef } from "react";
import { fetch_DownloadStudentVerifiedOrders, bulkorder, fetch_DownloadSelectedPreparedOrders, get_org, get_order_by_id, get_three_images, reorder } from "./utils/fetch";
import { get_toggled_url } from "./utils/aws_utils";
import { useNavigate, Link } from 'react-router-dom';
import axiosInstance from "./axiosInstance";
import './Dashboard.css'
import Redesign from './images/redesign.png'
import SuccessModal from "./SuccessModal";
import CustomLoader from "./Loader";
import { data, event } from "jquery";
import ZoomItem from "./zoom";
import html2canvas from 'html2canvas';
import { enhanceImageClarity } from './utils/enhanceimageclarity'
import BackToTopButton from "./Backtotopbutton";
import OrderList from "./OrderCard";
import OrderListView from "./OrderList";

function HomePage() {
  const navigate = useNavigate();
  const [vectorTaskId, setVectorTaskId] = useState(false)
  const [vectorProgress, setVectorProgress] = useState(0)

  const [orders, setOrders] = useState([]);
  const [visibleSection, setVisibleSection] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [lastFetched, setLastFetched] = useState("");
  const [filter, setFilter] = useState("All");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [userId_modal, setUserIdModal] = useState('');
  const [orderId_modal, setOrderIdModal] = useState('');
  const [email_modal, setEmailModal] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [orgFilter, setOrgFilter] = useState("All");
  const [sizefilter, setSizefilter] = useState("");
  const [autogenFilter, setAutogenFilter] = useState(false);
  const [orginalFilter, setOriginalFilter] = useState(false);
  const [forceReload, setForceReload] = useState(false)
  const [statuschange, setStatuschange] = useState(false);
  const [changedOrder, setChangedOrder] = useState('');
  const [toggledUrls, setToggledUrls] = useState({});
  const [verifying, setVeryfing] = useState(false)
  const [is_list_view, set_is_llist_veiw] = useState(false)
  const fetchOrders = async () => {
    try {
      const res = await axiosInstance.post("/admin_orders");
      const fetchedOrders = typeof res.data == 'string' ? JSON.parse(res.data) : res.data;
      console.log("Fetched orders:", fetchedOrders)

      setOrders(fetchedOrders);
      setFilteredOrders(fetchedOrders);
      setLastFetched(new Date().toLocaleString());
      const initialImageIndices = {};
      fetchedOrders.forEach(order => {
        initialImageIndices[order.order_id] = 0; 
      });
      setCurrentImageIndex(initialImageIndices);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
    finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchOrders();
  },[]);
  
  useEffect(() => {
    if(!vectorTaskId)
      return

    let server = process.env.REACT_APP_SERVER_NEW
    let con_type = server.indexOf('https') >= 0 ? 'wss' : 'ws'
    server = server.split('//')[1]
    const ws = new WebSocket(`${con_type}://${server}/ws/progress/${vectorTaskId}`);
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data, (((data.success || 0) + (data.failed || 0)) / (data.total || 1)*100))
      setVectorProgress((((data.success || 0) + (data.failed || 0)) / (data.total || 1))*100);
    };

    ws.onclose = () => {
      console.log("WebSocket closed");
      setVectorProgress(0);
    };

    return () => {
      ws.close();
    };
  }, [vectorTaskId]);
  
  const deleteOrder = async (userId, orderId) => {
    try{
      const payload = {
        'user_id': userId,
        'order_id': orderId
      }
      const response = await axiosInstance.post("/delete_order", payload);
      if (response.status === 200) {
        console.log('Order deleted successfully:', response.data);
      } else {
        console.error('Failed to delete an order, status code:', response.status);
      }
    } catch (err) {
      console.error("Error deleteing order status:", err);
    }
  }
  const updateOrderStatus = async (userId, orderId, newStatus, email, reason) => {
    try {
      if(reason)
        reason = ", Because of the following reason : "+reason
      const payload = {
        email_data : {
          "to_mail":email,
          "subject":"Order Status Update",
          "content": "Your order status for order_id : "+orderId+" is "+newStatus+reason
        },
        user_id: userId,
        order_id: orderId,
        new_status: newStatus,
      };
      const response = await axiosInstance.post("/update_order_status", payload);
      
      if (response.status === 200) {
        console.log('Order status updated successfully:', response.data);
      } else {
        console.error('Failed to update order status with status code:', response.status);
      }
    } catch (err) {
      console.error("Error updating order status:", err);
    }
  };

  const updateBulkOrderStatus = async (orders) => {
    try {
      const payload = orders.map(order => {
      let reason = order.reason ? `, Because of the following reason: ${order.reason}` : '';
      return {
        email_data: {
          "to_mail": order.email,
          "subject": "Order Status Update",
          "content": `Your order status for order_id: ${order.order_id} is ${order.new_status}${reason}`
        },
        user_id: order.user_id,
        order_id: order.order_id,
        new_status: order.new_status,
      };
    });
      const response = await axiosInstance.post("/update_bulk_order_status", payload);
      
      if (response.status === 200) {
        console.log('Order status updated successfully:', response.data);
      } else {
        console.error('Failed to update order status with status code:', response.status);
      }
    } catch (err) {
      console.error("Error updating order status:", err);
    }
  };

  useEffect(() => {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    console.log('refiltering...')
    let filtered = orders;
  
    if (filter !== "All") {
      filtered = filtered.filter(order => order.status === filter);
    } else {
      filtered = filtered.filter(order => order.status !== 'unpaid');
    }
  
    if (searchQuery) {
      filtered = filtered.filter(order => {
        const name = order.shipping_info?.firstName?.toLowerCase() || '';
        const email = order.shipping_info?.email?.toLowerCase() || '';
        return name.includes(lowerCaseSearchQuery) || email.includes(lowerCaseSearchQuery);
      });
    }

    if (orgFilter && orgFilter != 'All') {
      filtered = filtered.filter(order => order.org_name && order.org_name.toLowerCase().includes(orgFilter.toLowerCase()));
    }
    if(autogenFilter) {
      filtered = filtered.filter(order => order.autogenerated == true);
    }
    if(orginalFilter) {
      // filtered = filtered.filter(order => order.autogenerated == false || order.autogenerated);
      filtered = filtered.filter(order => order.autogenerated != true);
    }
    if(sizefilter && sizefilter != 'All') {
      filtered = filtered.filter(order => order.item.some(item => item.size === sizefilter))
    }
  
    setFilteredOrders(filtered);
    setSelectedOrders([]);
  }, [filter, orders, searchQuery, orgFilter, autogenFilter, forceReload, orginalFilter,sizefilter]);
  
  

  const toggleSection = (orderId, section) => {
    setVisibleSection(prevSections => ({
      ...prevSections,
      [orderId]: {
        ...(prevSections[orderId] || {}),
        [section]: !(prevSections[orderId] || {})[section]
      }
    }));
  };

  const handleImageChange = (orderId, length, next) => {
    setCurrentImageIndex(prevIndices => ({
      ...prevIndices,
      [orderId]: next ? (prevIndices[orderId] + 1) % length : (prevIndices[orderId] - 1 + length) % length
    }));
  };
  const update_filtered_grid = (orderId, status) => {
    const _orders = [...orders]; 
    for (const order of _orders) {
      if (order.order_id && order.order_id === orderId) {
        if (status === 'delete') {
          const index = _orders.indexOf(order);
          if (index > -1) {
            _orders.splice(index, 1);
          }
          break;
        }
        order.status = status;
        break;
      }
    }
    setOrders(_orders);
    setForceReload(!forceReload);
  };
  
  const handleDelete = async (userId, orderId, email) => {
    try {
      await deleteOrder(userId, orderId);
      update_filtered_grid(orderId, 'delete')
      console.log('Order deleted successfully');
    } catch (error) {
      console.error('Failed to delete an order, status:', error);
    }
  }
  const handleResetStatus = async (userId, orderId, email) => {
    setStatuschange(true);
    setChangedOrder(orderId)
    const newStatus = 'pending';  
    try {
      await updateOrderStatus(userId, orderId, newStatus, email);
      update_filtered_grid(orderId, 'pending')
      setStatuschange(false);
      setChangedOrder('');
      console.log('Order status reset to pending successfully');
    } catch (error) {
      console.error('Failed to reset order status:', error);
    }
  };
  
  const handlePrint = async(payload) => {
    try {
      if(payload && payload['user_info'])
        delete payload['user_info']
      if(payload && payload['_id'])
        delete payload['_id']

      if(payload && payload['item'])
      {
        for(var item in payload['item']){
          if(payload && payload['item'] && payload['item'][item] && payload['item'][item]['timestamp'] && payload['item'][item]['timestamp']['$date']){
            payload['item'][item]['timestamp'] = payload['item'][item]['timestamp']['$date']
          }
        }
      }

      if(payload && payload['timestamp'] && payload['timestamp']['$date'])
        payload['timestamp'] = payload['timestamp']['$date']

      const response = await axiosInstance.post("/print_order", payload);
      if (response.status === 200) {
        console.log('Order added successfully:', response.data);
      } else {
        console.error('Failed to update order status with status code:', response.status);
      }
    } catch (err) {
      console.error("Error updating order status:", err);
    }
  };

  const handleVerify = async (userId, orderId, email) => {
    setStatuschange(true);
    setChangedOrder(orderId)
    const newStatus = 'verified'; 
    try {
      await updateOrderStatus(userId, orderId, newStatus, email);
      update_filtered_grid(orderId, 'verified')
      setStatuschange(false)
      setChangedOrder('');
      console.log('Order verified');
    } catch (error) {
      console.error('Failed to verify order:', error);
    }
  };
  const [selectedOrders, setSelectedOrders] = useState([]);
  const handleVerifyAll = async (orders) => {
    setStatuschange(true);
  
    const bulkPayload = orders.map(order => {
      const { userId, orderId, email } = order;
      return {
        email: email,
        user_id: userId,
        order_id: orderId,
        new_status: 'verified',
      };
    });
  
    try {
      await updateBulkOrderStatus(bulkPayload);
      for (const order of orders) {
        const { orderId } = order;
        update_filtered_grid(orderId, 'verified');
        console.log(`Order ${orderId} verified`);
      }
    } catch (error) {
      console.error('Failed to verify orders:', error);
    }
  
    setStatuschange(false);
    setChangedOrder('');
  };

  const [verifymessage, setVerifymessage] = useState('')
  const handleSelectOrder = (userId, orderId, email, isChecked) => {
    setVerifymessage('');
    if (isChecked) {
      setSelectedOrders(prevSelected => [
        ...prevSelected,
        { userId, orderId, email }
      ]);
    } else {
      setSelectedOrders(prevSelected => 
        prevSelected.filter(order => order.orderId !== orderId)
      );
    }
  };
  
  const handleDownloadAll = async () => {
    if(selectedOrders.length === 0){
      setVerifymessage('Select any orders to download')
    } else {
    setVeryfing(true)
    try {
      const run = await handleVerifyAll(selectedOrders); 
      console.log(run);
      setSelectedOrders([]);
    } catch (error) {
      console.error('Error in handleDownloadAll:', error);
    }
    setVeryfing(false)
  }
  };
  const isOrderSelected = (orderId) => {
    return selectedOrders.some(order => order.orderId === orderId);
  };
  
  
  const handleCancelOrder = async (userId, orderId, email, reason) => {
    const newStatus = 'cancelled'; 
    try {
      await updateOrderStatus(userId, orderId, newStatus, email, reason);
      update_filtered_grid(orderId, 'cancelled')
      console.log('Order cancelled successfully');
    } catch (error) {
      console.error('Failed to cancel order:', error);
    }
    fetchOrders();
  };
  function HandleFilter(value){
    if (filter !== value) {    
    setFilter(value);
    console.log(value);
    }
  }
  const Preloader = () => {
    return (
        <div className="preloader"></div>
    );
};

  const TickIcon = () => {
      return (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="20" width="20" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
      );
  };
  const SenttoPrintify = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 8h-1V3H6v5H5c-1.11 0-2 .9-2 2v7h4v4h10v-4h4v-7c0-1.1-.9-2-2-2zm-5 11H10v-5h4v5zm3-11H7V5h10v3z"/>
    </svg>
    
    )
  }
  const CancelIcon = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
</svg>

    );
  }
  const ResettoPending = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"  fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <circle cx="12" cy="12" r="10"></circle>
  <line x1="12" y1="16" x2="12" y2="12"></line>
  <line x1="12" y1="8" x2="12" y2="8"></line>
  <line x1="8" y1="12" x2="8" y2="12"></line>
  <line x1="16" y1="12" x2="16" y2="12"></line>
</svg>

    )
  }
  const DeleteBtn = () => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path fill="currentColor" d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
    </svg>    
  )
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    
    const closeModal = () => setIsModalOpen(false);
    const handleReasonChange = (e) => setCancelReason(e.target.value);

    const handleModal = (userId, orderId, email_id) => {
      setUserIdModal(userId);
      setOrderIdModal(orderId);
      setEmailModal(email_id);
      setIsModalOpen(true)
    }

    const submitCancellation = (userId, orderId, reason, email_id) => {
      console.log('user Id : ',userId,' order Id : ',orderId, ' email Id : ', email_id, ' Reason : ', reason)
        handleCancelOrder(userId, orderId, email_id, reason)
        closeModal();
    };
    
    const apparels = Array.from(new Set(orders.flatMap(order => order.item.map(item => item.size || "All" ))));
    if (!apparels.includes("All")) {
      apparels.unshift("All");
    }
    const orgNames = Array.from(new Set(orders.map(order => order.org_name || "All")));
    if (!orgNames.includes("All")) {
      orgNames.unshift("All");
    }
    const [isredesignmodalopen, setIsRedesignmodalopen] = useState(false);
    const [isDownloadmodalopen, setIsDownloadmodalopen] = useState(false);
    const [password, setPassword] = useState('')
    const [redata, setReData] = useState('')
    const [newprompt, setNewPrompt] = useState('')
    const [orderloading, setOrderLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handlePasswordChange = (e) => setPassword(e.target.value)
    const handlenewpromptChange = (e) => setNewPrompt(e.target.value)
    const closeRedesignModel = () => setIsRedesignmodalopen(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [incorrectpassword, setIncorrectPassword] = useState(false);
    const [imageloaded, setImageLoaded] = useState(false)
    const [isimagegenerated, setIsImageGenerated]= useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [selectedPrompt, setSelectedPrompt] = useState('')
    const RedesignImg = (data) => {
      setIsRedesignmodalopen(true)
      setReData(data)
    }
    const handleVerifyDownload = () => {
      setIsDownloadmodalopen(true);
      
    }
    const handleCancel =()=>{
      setIsDownloadmodalopen(false);
    }
    const handlePreparedDownload = async(e) => {
      setDownloadLoading(true);
      try{
        let order_ids = []
        for(var i=0; i<filteredOrders.length; i++)
          filteredOrders[i]['order_id'] ? order_ids.push(filteredOrders[i]['order_id']) : console.log(filteredOrders[i])
      
        const succeeded = await fetch_DownloadSelectedPreparedOrders(order_ids)
        if(succeeded.status === '200' || parseInt(succeeded.status,10) === 200){
          for(var i=0; i<order_ids.length; i++)
            update_filtered_grid(order_ids[i], 'shipped')
          console.log("success")
        }
        else{
          console.log('failed', succeeded)
        }
      }
      catch(error){
        console.log(error);
      }
      finally{ 
        setDownloadLoading(false)
      };
    }
    const handleConfirm = async (e) => {
      console.log("vectorizer mode : production")
      setDownloadLoading(true);
      console.log(password)
      try{
        let order_ids = []
        for(var i=0; i<filteredOrders.length; i++)
          filteredOrders[i]['order_id'] ? order_ids.push(filteredOrders[i]['order_id']) : console.log(filteredOrders[i])
        console.log(order_ids)
      
      var task_id = Date.now()
      setTimeout(()=>{setVectorTaskId(task_id)}, 2000)
      const succeeded = await fetch_DownloadStudentVerifiedOrders(password, 'production', order_ids, task_id)
      setVectorTaskId(false)
      console.log("resposnse for api : ",succeeded)
      if(succeeded.status === '200' || parseInt(succeeded.status,10) === 200){
        console.log("success")
        setIsDownloadmodalopen(false)
        setTimeout(() => {
          navigate('/orders')
        }, 2000);
      } else if (succeeded === '403'|| parseInt(succeeded, 10) === 403) {
        console.log("incorrect password");
        setIncorrectPassword(true);
      }
    }
      catch(error){
        console.log(error);
      }
      finally{ 
        setDownloadLoading(false)
      };
    }
    const handleTestConfirm = async (e) => {
      console.log("vectorizer mode : test")
      setDownloadLoading(true);
      console.log(password)
      try{
        let order_ids = []
        for(var i=0; i<filteredOrders.length; i++)
          filteredOrders[i]['order_id'] ? order_ids.push(filteredOrders[i]['order_id']) : console.log(filteredOrders[i])
        console.log(order_ids)

      var task_id = Date.now()
      setVectorTaskId(task_id)
      const succeeded = await fetch_DownloadStudentVerifiedOrders(password, 'test', order_ids, task_id)
      setVectorTaskId(false)
      console.log("resposnse for api : ",succeeded)
      if(succeeded.status === '200' || parseInt(succeeded.status,10) === 200){
        console.log("success")
        setIsDownloadmodalopen(false)
        setTimeout(() => {
          navigate('/orders')
        }, 2000);
      } else if (succeeded === '403'|| parseInt(succeeded, 10) === 403) {
        console.log("incorrect password");
        setIncorrectPassword(true);
      }
    }
      catch(error){
        console.log(error);
      }
      finally{ 
        setDownloadLoading(false)
      };
    }
    const updateToggledUrls = (orderId, toggled_url) => {
      console.log("toggled url updating")
      setToggledUrls((prevUrls) => {
        const Urls = { ...prevUrls };
        Urls[orderId] = toggled_url;
        return Urls;
      });
    };
    const updateOrderImageUrl = async (orderId,toggled) => {
      const order_id = {
        order_id: orderId
      }
      try {
        const response = await get_order_by_id(order_id);
        const updatedOrder = response;
        console.log("update order : ",updatedOrder)
        setFilteredOrders((prevFilteredOrders) =>
          prevFilteredOrders.map((order) =>
            order.order_id === orderId ? updatedOrder : order
          )
        );
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId ? updatedOrder : order
          )
        );
        const toggledStatus = updatedOrder.item[0]?.toggled;
        console.log(toggledStatus)
        if (toggled === true) {
          updateToggledUrls(orderId, toggledStatus);
          setFilteredOrders((prevFilteredOrders) =>
          prevFilteredOrders.map((order) =>
            order.order_id === orderId
              ? {
                  ...order,
                  item: order.item.map((item, index) =>
                    index === 0
                      ? { ...item, toggled: true }  // Update the `toggled` field to `true`
                      : item
                  )
                }
              : order
          )
          );
          setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId
              ? {
                  ...order,
                  item: order.item.map((item, index) =>
                    index === 0
                      ? { ...item, toggled: true }  // Update the `toggled` field to `true`
                      : item
                  )
                }
              : order
          )
          );
        }
        else {
          setFilteredOrders((prevFilteredOrders) =>
          prevFilteredOrders.map((order) =>
            order.order_id === orderId
              ? {
                  ...order,
                  item: order.item.map((item, index) =>
                    index === 0
                      ? { ...item, toggled: false }  // Update the `toggled` field to `False`
                      : item
                  )
                }
              : order
          )
          );
          setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId
              ? {
                  ...order,
                  item: order.item.map((item, index) =>
                    index === 0
                      ? { ...item, toggled: false }  // Update the `toggled` field to `False`
                      : item
                  )
                }
              : order
          )
          );
        }
      } catch (error) {
        console.error('Error updating order image URL:', error);
      } finally {
        console.log("toggled urls after updation : ",toggledUrls)
        console.log("updated order : ",filteredOrders)
      }
    };
    const generate_three_images = async (newprompt, password, data) => {
      setImageLoaded(true)
      const prompt = {
        prompts: newprompt,
        password: password
      }
      try {
        setIsImageGenerated(true);
        const three_images_data = await get_three_images(prompt);
        setIsImageGenerated(false);
        displayImages(three_images_data.data, three_images_data.prompt);        
      }
      catch(e) {
        console.log("error generating image : ",e)
      }
    }
    const displayImages = (images, prompt) => {
      // Ensure images is an array with exactly 3 items
      if (!Array.isArray(images) || images.length !== 3) {
        console.error("Expected an array of 3 base64 image data strings");
        return;
      }
    
      const container = document.getElementById('image-container-regenerate');
      // container.innerHTML = ''; // Clear previous images
    
      images.forEach((imageData, index) => {
        // Create a new div for each image
        const imageDiv = document.createElement('div');
        imageDiv.className = 'image-item';
        
        // Create an img element and set the source
        const img = document.createElement('img');
        img.src = `${imageData}`; // Use the base64 data
        img.alt = `Generated Image ${index + 1}`;
        img.className = 'generated-image';
        
        imageDiv.appendChild(img);
        container.appendChild(imageDiv);
        
        // Add click event listener to handle image selection
        imageDiv.addEventListener('click', () => {
          handleImageSelect(imageData,prompt[index]);
        });
      });
    };
    
    const handleImageSelect = (imageData, prompt) => {
      // Reset previously selected image
      setSelectedImage(null);
      setSelectedPrompt(null);
    
      // Set new selected image
      setSelectedImage(imageData);
      setSelectedPrompt(prompt);
    
      // Remove 'selected' class from all image divs
      document.querySelectorAll('.image-item').forEach(div => {
        div.classList.remove('selected');
      });
    
      // Add 'selected' class to the clicked image's div
      const selectedDiv = Array.from(document.querySelectorAll('.image-item')).find(div => {
        return div.querySelector('img').src === imageData;
      });
    
      if (selectedDiv) {
        selectedDiv.classList.add('selected');
      }
    };
    const [isorderplacing, setisorderplacing] = useState(false)
    const handleOkClick = () => {
      const file = [{
        ID: redata.order_id,
        order_id: redata.order_id,
        last_name: redata.shipping_info.lastName,
        first_name: redata.shipping_info.firstName,
        img_url: selectedImage,
        email: redata.shipping_info.email,
        'shirt-size': redata.item[0].size,
        apparel: redata.item[0].apparel,
        price: redata.item[0].price,
        toggled: 'NULL',
        prompt: selectedPrompt,
        streetAddress: redata.shipping_info.streetAddress,
        streetAddress2: redata.shipping_info.streetAddress2,
        city: redata.shipping_info.city,
        state: redata.shipping_info.stateProvince,
        postalZipcode: redata.shipping_info.postalZipcode,
        phone: redata.shipping_info.phone,
        color: redata.item[0].color,
        org_id: redata.org_id,
        org_name: redata.org_name
      }];
      redesignImage(file);
    };
    const redesignImage = async (file) => { 
      setisorderplacing(true)   
      const formData = {
        file: file
      }  
      setOrderLoading(true)
      try {
        const succeeded = await reorder(formData);
        console.log(succeeded)
        if (succeeded[0]?.order_id) {
          console.log('Bulk Order Placed successfully:', file);
          setShowSuccessModal(true);
          updateOrderImageUrl(redata.order_id,false)
        } else if(succeeded === 403 || parseInt(succeeded, 10) === 403){
          console.log("erroe in password")
          setErrorMessage('Incorrect Password...')
        } else {
          console.log('Failed to Order:', succeeded);
        }
      } catch (error) {
        console.log('Error placing bulk order:', error);
      } finally {
        setisorderplacing(false)
        setReData('');
        setImageLoaded(false)
        setOrderLoading(false);
        setIsRedesignmodalopen(false)
        setShowSuccessModal(false);
      }
    }
    
    const handleCheckboxChange = (event) => {
      setAutogenFilter(event.target.checked);
      if(orginalFilter){
        setOriginalFilter(false);
      }
    };
    const handleOrginalChange = (event) => {
      setOriginalFilter(event.target.checked);
      if(autogenFilter){
        setAutogenFilter(false);
      }
    };
    const handleviewchange = (event) => {
        set_is_llist_veiw(!is_list_view)

    }
    const [isZoomEnabled, setIsZoomEnabled] = useState(false);
    const [zoomItemSrc, setzoomItemSrc] = useState('');
    const editedImageRef = useRef(null);
    const [apparel_img, setapparel_img] = useState('');
    const [zoomerImg, setZoomerImg] = useState('');
    const [orgdata, setorgdata] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isordercreating, setisordercreating] = useState(false);
    const [dimArray, setDimArray] = useState({
      Dim_top: 0,
      Dim_left: 0,
      Dim_height: 0,
      Dim_width: 0
    });
    const [orderData, setOrderData] = useState('')
    
        
    var task_id = (Date.now()).toString()
    const toggled_image = async (data,editedImg) => {      
      const file = [{
        ID: data.order_id,
        order_id: data.order_id,
        img_id: data.item[0].img_id,
        prompt: data.item[0].prompt,
        last_name: data.shipping_info.lastName,
        first_name: data.shipping_info.firstName,
        email: data.shipping_info.email,
        'shirt-size': data.item[0].size,
        apparel: data.item[0].apparel,
        price: data.item[0].price,
        toggled: editedImg,
        streetAddress: data.shipping_info.streetAddress,
        streetAddress2: data.shipping_info.streetAddress2,
        city: data.shipping_info.city,
        state: data.shipping_info.stateProvince,
        postalZipcode: data.shipping_info.postalZipcode,
        phone: data.shipping_info.phone,
        color: data.item[0].color,
        org_id: data.org_id,
        org_name: data.org_name
      }];
    
      const formData = {
        file: file,
        prompts: [''],
        numImages: 1,
        password: 'Drophouse23#',
        is_prompt: false,
        is_toggled: true,
        task_id: task_id,
      };
    
      console.log("Data for the redesign:", formData);
      try {
        const succeeded = await bulkorder(formData);
        console.log(succeeded)
        if (succeeded[0]?.order_id) {
          console.log('Design Changed successfully:', formData);
          await updateOrderImageUrl(data.order_id,true)
        } else if(succeeded === 403 || parseInt(succeeded, 10) === 403){
          console.log("error in password")
          setErrorMessage('Incorrect Password...')
        } else {
          console.log('Failed to Order:', succeeded);
        }
      } catch (error) {
        console.log('Error saving design with order:', error);
      } finally {
        setIsZoomEnabled(false);
      }
      return true
    }
    const closeZoomerwindow = () => {
      setIsZoomEnabled(!isZoomEnabled);
      
    }
    const handleEditDesign = async (org_id,apparel,color,img_url,order_data) => {
      setIsZoomEnabled(true)
      setIsLoading(true);
      setOrderData(order_data);
      const payload = {
        org_id: org_id,
        apparel: apparel,
        color: color,
        img_url: img_url
      }
      try{
        const org_data = await get_org(payload)
        setorgdata(org_data);
        setDimArray({
          Dim_top: org_data.Dim_Top,
          Dim_left: org_data.Dim_Left,
          Dim_height: org_data.Dim_height,
          Dim_width: org_data.Dim_width
        })
        setapparel_img(org_data.color_asset)/*for apparel image */
        setzoomItemSrc(org_data.base64_img);/*for design image */
        setZoomerImg(org_data.mock_img)/* for zoomer mock */
        setIsZoomEnabled(true)
      }
      catch(e){
        console.log("error while fetching apperal : " ,e)
      }
      setIsLoading(false);
    }
      const saveAsImage = async () => {
        setisordercreating(true)
        try{
        const imageRef = editedImageRef.current.getEditedImageRef();
        const transformedCanvas = document.createElement('canvas');
        transformedCanvas.width = 512;
        transformedCanvas.height = 512;
        const ctx = transformedCanvas.getContext('2d');
        const image = new Image();
        let tmp = imageRef.current.innerHTML;
        imageRef.current.innerHTML = '';
        const canvas = await html2canvas(imageRef.current, {
          allowTaint : false,
          useCORS: true,
          scale: 4
        });
        image.src = canvas.toDataURL("image/jpeg");
        await Promise.all([image.decode()]);
        ctx.drawImage(image, 0, 0, transformedCanvas.width, transformedCanvas.height);
        imageRef.current.innerHTML = tmp;
        let imgSrc = transformedCanvas.toDataURL("image/jpeg");
        const enhancedImage = await enhanceImageClarity(imgSrc);
        return enhancedImage;
      }
      catch(err){
        console.log('Error saving image:', err);
        return null;
      }
      }
      const toggleZoom = async () => {
        setisordercreating(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        try{
          const enhancedImage = await saveAsImage(orderData);
          if (enhancedImage) {
            const pass = await toggled_image(orderData, enhancedImage); // Use the returned enhancedImage
            if (pass) {
              console.log("Image saved successfully");
            } else {
              console.log("Error in toggled_image");
            }
          } else {
            console.log("Error saving the image");
          }
          setIsZoomEnabled(!isZoomEnabled);
      }
      catch{
        console.log("error saving design")
      }
      setisordercreating(false);
      };
  return (
    <div>{loading ? (
      <div className="loader-container">
      <div className="loader-text">Fetching Orders...</div>
  </div>
  ) : (
    <>
    <div className="my-2 md:my-4 main-div-dashboard">
      <div className={isZoomEnabled ? `no-scroll`: ``}>
      <div className="mx-4 mb-3 md:mb-5 flex flex-col md:flex-row items-center justify-between">
        <div>
          <div><b>Last updated:</b> {lastFetched}</div>
        </div>
        <Link to="/bulkorder" className="button-bulk-order">Auto Generate</Link>
        {/* <div style={{width:'60%'}} className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-3 md:mt-0"> */}
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-3 md:mt-0 w-full lg:w-3/5">
        <div className="select-container" style={{width: '100%'}}>
          Filter by Organization Name:
          <select value={orgFilter} onChange={e => setOrgFilter(e.target.value)}>
            {orgNames.map(orgName => (
              <option key={orgName} value={orgName}>{orgName}</option>
            ))}
          </select>
          </div>
          <div className="select-container" style={{width: '100%'}}>
          Filter by Apparel sizes:
          <select value={sizefilter} onChange={e => setSizefilter(e.target.value)}>
            {apparels.map(apparel => (
              <option key={apparel} value={apparel}>{apparel}</option>
            ))}
          </select>
          </div>
        </div>
      </div>
      <div className="nav-bar-filter w-full row">
        <h2 className={`${filter} ${(filter == 'All' ? `selected`: ``)}`} onClick={() => HandleFilter('All')} value="All">All</h2>
        <h2 className={`${filter} ${(filter == 'pending' ? `selected`: ``)}`} onClick={() => HandleFilter('pending')}  value="pending">Pending</h2>
        <h2 className={`${filter} ${(filter == 'verified' ? `selected`: ``)}`} onClick={() => HandleFilter('verified')}  value="verified">Verified</h2>
        <h2 className={`${filter} ${(filter == 'prepared' ? `selected`: ``)}`} onClick={() => HandleFilter('prepared')}  value="prepared">Prepared</h2>
        <h2 className={`${filter} ${(filter == 'shipped' ? `selected`: ``)}`} onClick={() => HandleFilter('shipped')} value="shipped">Shipped</h2>
        <h2 className={`${filter} ${(filter == 'delivered' ? `selected`: ``)}`} onClick={() => HandleFilter('delivered')} value="delivered">Delivered</h2>
        <h2 className={`${filter} ${(filter == 'cancelled' ? `selected`: ``)}`} onClick={() => HandleFilter('cancelled')}  value="cancelled">Cancelled</h2>
      </div>
      <div className="mobile-dropdown">
                <button onClick={toggleDropdown} className="dropdown-toggle">
                    {filter}
                    <span className="dropdown-arrow">{dropdownOpen ? '▲' : '▼'}</span>
                </button>
                {dropdownOpen && (
                    <div className="dropdown-menu">
                        <h2 onClick={() => { HandleFilter('All'); toggleDropdown(); }} value="All">All</h2>
                        <h2 onClick={() => { HandleFilter('pending'); toggleDropdown(); }} value="pending">Pending</h2>
                        <h2 onClick={() => { HandleFilter('prepared'); toggleDropdown(); }} value="prepared">Prepared</h2>
                        <h2 onClick={() => { HandleFilter('verified'); toggleDropdown(); }} value="verified">Verified</h2>
                        <h2 onClick={() => { HandleFilter('shipped'); toggleDropdown(); }} value="shipped">Shipped</h2>
                        <h2 onClick={() => { HandleFilter('delivered'); toggleDropdown(); }} value="delivered">Delivered</h2>
                        <h2 onClick={() => { HandleFilter('cancelled'); toggleDropdown(); }} value="cancelled">Cancelled</h2>
                    </div>
                )}
            </div>
      <div className="glowing-div">
        {/* <div className="flex"> */}
        <div className="flex flex-row-filters">
          <h2><b>Orders : {filteredOrders.length}</b></h2>
        <input
            type="text"
            placeholder="Search by name or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
            style={{position: 'relative',top: '-10px',left: '15px'}}
          />
          <div className="autogen-filter">
            <input type="checkbox" id="autogen-checkbox" checked={autogenFilter} onChange={handleCheckboxChange}/>
            <label htmlFor="autogen-checkbox">
              Show only Auto Generated Orders
            </label>
          </div>
          <div className="original-filter">
            <input type="checkbox" id="original-checkbox" checked={orginalFilter} onChange={handleOrginalChange}/>
            <label htmlFor="original-checkbox">
              Show Original Orders
            </label>
          </div>
          {filteredOrders.length && filteredOrders.every(order => order.status === 'verified') > 0 ? (
  <button
    className="mt-3 bg-blue-500 hover:bg-blue-400 text-white py-1 px-3 rounded transition duration-150 ease-in-out text-sm text-center mt-0 ml-auto mb-4 flex items-center justify-center"
    onClick={handleVerifyDownload}
    style={{ backgroundColor: downloadLoading ? 'grey' : 'blue' }}
    disabled={downloadLoading}
  >
    {downloadLoading ? (
      <>
        <div className="spinner"></div> 
        Downloading
      </>
    ) : (
      'Download'
    )}
  </button>
) : null}
          {filteredOrders.length && filteredOrders.every(order => order.status === 'prepared') > 0 ? (
  <button
    className="mt-3 bg-blue-500 hover:bg-blue-400 text-white py-1 px-3 rounded transition duration-150 ease-in-out text-sm text-center mt-0 ml-auto mb-4 flex items-center justify-center"
    onClick={handlePreparedDownload}
    style={{ backgroundColor: downloadLoading ? 'grey' : 'blue' }}
    disabled={downloadLoading}
  >
    {downloadLoading ? (
      <>
        <div className="spinner"></div> 
        Downloading
      </>
    ) : (
      'Download'
    )}
  </button>
) : null}
{filteredOrders.length && filteredOrders.every(order => order.status === 'pending') > 0 ? (
  <>
  <button
    className="mt-3 bg-blue-500 hover:bg-blue-400 text-white py-1 px-3 rounded transition duration-150 ease-in-out text-sm text-center mt-0 ml-auto mb-4 flex items-center justify-center"
    onClick={handleDownloadAll}
    style={{ backgroundColor: verifying ? 'grey' : 'blue' }}
    disabled={verifying}
  >
    {verifying ? (
      <>
        <div className="spinner"></div> 
        Verifying
      </>
    ) : (
      'verify selected'
    )}
  </button>
  {verifymessage && <span>{verifymessage}</span>}
  </>
) : null}
        </div>

        <div className="view-filter" style={{margin: `20px 0`}}>
            <input type="checkbox" id="view-checkbox" checked={is_list_view} onChange={handleviewchange}/>
            <label htmlFor="view-checkbox">
              List View
            </label>
        </div>
        {is_list_view ?
        <>
        <OrderListView
              filteredOrders={filteredOrders}
              toggledUrls={toggledUrls}
              currentImageIndex={currentImageIndex}
              visibleSection={visibleSection}
              handleImageChange={handleImageChange}
              RedesignImg={RedesignImg}
              handleSelectOrder={handleSelectOrder}
              handleResetStatus={handleResetStatus}
              handleVerify={handleVerify}
              handleModal={handleModal}
              handleDelete={handleDelete}
              handlePrint={handlePrint}
              toggleSection={toggleSection}
              handleEditDesign={handleEditDesign}
              isOrderSelected={isOrderSelected}
              statuschange={statuschange}
              changedOrder={changedOrder}
          />
        </>
        :<OrderList
              filteredOrders={filteredOrders}
              toggledUrls={toggledUrls}
              currentImageIndex={currentImageIndex}
              visibleSection={visibleSection}
              handleImageChange={handleImageChange}
              RedesignImg={RedesignImg}
              handleSelectOrder={handleSelectOrder}
              handleResetStatus={handleResetStatus}
              handleVerify={handleVerify}
              handleModal={handleModal}
              handleDelete={handleDelete}
              handlePrint={handlePrint}
              toggleSection={toggleSection}
              handleEditDesign={handleEditDesign}
              isOrderSelected={isOrderSelected}
              statuschange={statuschange}
              changedOrder={changedOrder}
          />
    }
      </div>
      </div>
      <ZoomItem
        isZoomEnabled={isZoomEnabled}
        imageSrc={zoomItemSrc}
        TshirtImageSrc={apparel_img}
        dimensions={dimArray}
        ZoomerMock={zoomerImg}
        Loading={isLoading}
        closeAction={closeZoomerwindow}
        ref={editedImageRef} />
        <div className={`Save-btn-contaiiner ${isZoomEnabled ? '' : 'hidden'}`}>
          {!isLoading && <button  onClick={toggleZoom} 
                          style={{fontFamily : `ARSENAL`, backgroundColor: `#800000`}}
                          className={`ok-btn text-zinc-100 font-extrabold rounded-xl text-xl inline-block w-4/12 md:w-2/12 lg:w-2/12 save-btn`}
                          disabled={isordercreating}>
                        {isordercreating ? <div className="save-spinner"></div>:'Save Design'}
                          </button>}          
      </div>
      {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{zIndex: `9999`}}>
                    <div className="bg-white p-4 rounded shadow-lg" style={{width: '50%'}}>
                        <h2 className="text-lg font-bold mb-4">Reason For Canceling the order :</h2>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Enter the reason for cancellation"
                            value={cancelReason}
                            style={{height: '30vh'}}
                            onChange={handleReasonChange}
                        />
                        <div className="flex justify-end mt-4">
                            <button
                                className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-3 rounded mr-2"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded"
                                onClick={() => submitCancellation(userId_modal, orderId_modal,cancelReason,email_modal)}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isredesignmodalopen && (
              <>
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{zIndex: `9999`}}>
                  {imageloaded ?
                  <>
                    {isimagegenerated && 
                      <div className="custom-loader-container">
                      <div className="custom-loader-spinner"></div>
                      <p className="custom-loader-text">Image Generation is in process...</p>
                      <p> It may take sometime, do not refresh or close the page.!!!</p>
                    </div>}
                    <div className="regenerate-div bg-white p-4 flex flex-col items-start">
                      {!isimagegenerated && <h1 className="select-txt">Please select any one of the image to place order!!!</h1>}
                      <div id="image-container-regenerate"></div>
                          {selectedImage && (
                            <div className="button-container">
                            {!isimagegenerated && <button className="ok-btn" onClick={handleOkClick} disabled={isorderplacing}>
                              {isorderplacing ? <div className="save-spinner"></div>: 'Ok'}
                            </button>}
                          </div>
                          )}
                    </div>
                    </>
                    :
                    <div className="bg-white p-4 rounded shadow-lg" style={{width: '50%'}}>
                    
                    {orderloading ? <CustomLoader order_id='1'/> :<>
                    <SuccessModal 
                    isOpen={showSuccessModal}
                    onClose={() => setShowSuccessModal(false)}
                    />
                    <h2 className="text-lg font-bold mb-4">Enter new prompt :</h2>
                        <input type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Enter new prompt"
                            value={newprompt}
                            style={{height: '50px'}}
                            onChange={handlenewpromptChange}
                        />
                        <h2 className="text-lg font-bold mb-4">Enter password to Regenerate the Image :</h2>
                        <input type="password"
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Enter the password"
                            value={password}
                            style={{height: '50px'}}
                            onChange={handlePasswordChange}
                        />
                        <span style={{color: 'red'}}>{errorMessage}</span>
                        <div className="flex justify-end mt-4">
                            <button
                                className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-3 rounded mr-2"
                                onClick={closeRedesignModel}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded"
                                onClick={() => generate_three_images(newprompt,password,redata)}
                            >
                                Regenerate Image
                            </button>
                        </div>
                    </>}
                    </div>
                    }
                </div>
                </>
            )}
            {isDownloadmodalopen && (
              <>
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{zIndex: `9999`}}>
                    <div className=" p-4 rounded shadow-lg" style={{width: '50%'}}>
                    {downloadLoading ? <div className="custom-loader-container">
    <div className="custom-loader-spinner"></div>
    <p className="custom-loader-text">Downloading order is in Process...</p>
    <p> It may take sometime, do not refresh or close the page.!!!</p>
    <div className="w-[100%] flex mt-3 ml-4">
      <progress value={vectorProgress} max="100" style={{width:"90%"}}></progress>
      <p className="ml-1">{Math.round(vectorProgress)}%</p>
    </div>
  </div> :<> 
  <div className="warning-modal">
          <div className="warning-modal-content">
            <h2 className="warning-modal-title">Attention!!!</h2>
            <p className="warning-modal-message">Are you sure you want to Download the images?</p>
            <p className="warning-modal-cost">Approximate cost: $0.2 per Image</p>

            <h2 className="text-lg font-bold mb-4">Enter password to Download the Images :</h2>
                        <input type="password"
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Enter the password"
                            value={password}
                            style={{height: '50px',marginBottom:'10px'}}
                            onChange={handlePasswordChange}
                        />
                       {incorrectpassword &&  <span style={{color: 'red'}}>Incorrect Password!!!</span>}
            <div className="warning-modal-actions">
              <button className="warning-modal-confirm-button" onClick={() => handleConfirm(password)}>Confirm</button>
              <button className="warning-modal-cancel-button" onClick={handleCancel}>Cancel</button>
            </div><br></br>
            <span>OR</span><br></br>
            <button className="warning-modal-confirm-button" style={{marginTop: '10px'}} onClick={() => handleTestConfirm(password)}>Test Download</button>
          </div>
        </div>
      </>}
                    </div>
                </div>
                </>
            )}
            <BackToTopButton />
    </div>
    </>
  )}
  </div>
  );
}  

export default HomePage;
