import React, { useState, useEffect } from 'react';
import { fetchAddtoOrganisation, fetchPrices, fetchOrganisationlist } from './utils/fetch';
import { useNavigate } from 'react-router-dom';
import ApparelPreview from './ApparelPreview';
import './Organisation.css'; // Import CSS for component styling
import RadioButtonGroup from './Radiobuttongroup';
import Tooltip from './components/Tooltip';

const OrganisationForm = ({ addOrganisation }) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [name, setName] = useState('');
    const [mask, setMask] = useState('');
    const [logo, setLogo] = useState('');
    const [themeColor, setThemeColor] = useState('#FF007F');
    const [font, setFont] = useState('');
    const [favicon, setFavicon] = useState('');
    const [products, setProducts] = useState([]);
    const [uploadedImage, setUploadedImage] = useState([]);
    const [org_id, setOrgID] = useState('');
    const [greenmask, setGreenMask] = useState('');
    const [productMasks, setProductMasks] = useState({});
    const [productGreenMasks, setProductGreenMasks] = useState({});
    const [productPics, setProductPics] = useState({});
    const [colorAssets, setColorAssets] = useState({});
    const [loading, setLoading] = useState(false)
    const [landingpage, setlandingpage] = useState([
        { name: '', asset: '', asset_back: ''},
        { name: '', asset: '', asset_back: ''},
        { name: '', asset: '', asset_back: ''},
        { name: '', asset: '', asset_back: ''}
    ]);
    const [productsList, setProductsList] = useState([]);
    const [expandedColors, setExpandedColors] = useState({});
    const [envData, setEnvData] = useState({
        REACT_APP_STRIPE_CHECKOUT_ENABLED: true,
        REACT_APP_SIDEBAR_PRODUCT_IMAGES_ENABLED: true,
        REACT_APP_CART_ENABLED: true,
        REACT_APP_SHOWPRICE: true,
        REACT_APP_SAMPLE_PRODUCT_SLIDER: true
    });


    useEffect(() => {
            const new_id = [...Array(16)].map(() => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))).join('');
            setOrgID(new_id.toString());
    }, [navigate]);

    useEffect(() => {
        fetchPrices(navigate)
            .then(items => {
                const name = Object.keys(items);
                setProductsList(name);
            });
    }, []);

    const handleLandingPageSampleChange = (index, key, value) => {
        const updatedSamples = [...landingpage];
        updatedSamples[index][key] = value;
        setlandingpage(updatedSamples);
    };
    const handleLandingPageSampleAssetChange = (index, e) => {
        const file = e.target.files[0];
        handleFileUpload(file, (base64String) => {
            handleLandingPageSampleChange(index, 'asset', base64String);
        });
    };
    const handleLandingPageSampleAssetbackChange = (index, e) => {
        const file = e.target.files[0];
        handleFileUpload(file, (base64String) => {
            handleLandingPageSampleChange(index, 'asset_back', base64String);
        });
    };
    const handleUpdateDimensions = (index, dimensions) => {
        const updatedProducts = [...products];
        updatedProducts[index].dimensions = dimensions;
        setProducts(updatedProducts);
    };
    const handleFileUpload = (file, callback) => {
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                callback('data:image/png;base64,' + base64String);
            };
            reader.readAsDataURL(file);
        } else {
            console.error('Invalid file type. Please upload a PNG or JPEG file.');
        }
    };
    const handleProductChange = (index, key, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][key] = value;
        setProducts(updatedProducts);
    };
    const handleProductColorChange = (index, colorKey, color) => {
        const updatedProducts = [...products];
        updatedProducts[index].colors[colorKey].color_map = color;
        setProducts(updatedProducts);
    };
    const handleProductColorNameChange = (index, colorKey, name) => {
        const updatedProducts = [...products];
        updatedProducts[index].colors[colorKey].name = name;
        setProducts(updatedProducts);
    };
    const handleProductColorAssetChange = (index, colorKey, side, e) => {

        const file = e.target.files[0];

        handleFileUpload(file, (base64String) => {
            setColorAssets(prevState => ({
                ...prevState,
                [colorKey]: {
                    ...prevState[colorKey],
                    [side]: base64String,
                },
            }));
            const updatedProducts = [...products];

            updatedProducts[index].colors[colorKey].asset[side] = base64String;

            setProducts(updatedProducts);

        });

    };
    const handleRemoveAsset = (index,colorKey, side, e) => {
        e.preventDefault();
        setColorAssets(prevState => ({
            ...prevState,
            [colorKey]: {
                ...prevState[colorKey],
                [side]: null,
            },
        }));
        handleProductColorAssetChange(index, colorKey, side, { target: { files: [] } });
    };
    const handleProductMaskChange = (index, e) => {
        const file = e.target.files[0];
        handleFileUpload(file, (base64String) => {
            setProductMasks(prevState => ({ ...prevState, [index] : base64String}))
            const updatedProducts = [...products];
            updatedProducts[index].mask = base64String;
            setProducts(updatedProducts);
        });
    };
    const handleProductGreenMaskChange = (index, e) => {
        const file = e.target.files[0];
        handleFileUpload(file, (base64String) => {
            setProductGreenMasks(prevState => ({ ...prevState, [index] : base64String}))
            const updatedProducts = [...products];
            updatedProducts[index].greenmask = base64String;
            setProducts(updatedProducts);
        });
    };
    const handleRemoveMask = (index) => {
        setProductMasks(prevState => ({ ...prevState, [index] : null}))
        handleProductMaskChange(index, { target: { files: [] } });
    };
    const handleRemoveGreenMask = (index) => {
        setProductGreenMasks(prevState => ({ ...prevState, [index] : null}))
        handleProductGreenMaskChange(index, { target: { files: [] } });
    };
    const handleProductDefaultImage = (index, e) => {
        const file = e.target.files[0];
        handleFileUpload(file, (base64String) => {
            setProductPics(prevState => ({ ...prevState, [index]: base64String }));
            const updatedProducts = [...products];
            updatedProducts[index].defaultProduct = base64String;
            setProducts(updatedProducts);
            setUploadedImage((prevImages) => {
                const newImages = [...prevImages];
                newImages[index] = base64String;
                return newImages;
            });
        });
    };
    const handleRemovePic = (index) => {
        setProductPics(prevState => ({ ...prevState, [index]: null }));
        handleProductDefaultImage(index, { target: { files: [] } });
    };
    const handleMaskUpload = (e) => {
        const file = e.target.files[0];
        handleFileUpload(file, setMask);
    };
    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        handleFileUpload(file, setLogo);
    };
    const handleFaviconUpload = (e) => {
        const file = e.target.files[0];
        handleFileUpload(file, setFavicon);
    };
    const handleGreenMaskUpload = (e) => {
        const file = e.target.files[0];
        handleFileUpload(file, setGreenMask);
    }
    const handleSizeChange = (index, size) => {
        const updatedProducts = [...products];
        const sizes = updatedProducts[index].sizes;
        if (sizes.includes(size)) {
            updatedProducts[index].sizes = sizes.filter(s => s !== size);
        } else {
            updatedProducts[index].sizes = [...sizes, size];
        }
        setProducts(updatedProducts);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const secrets = {
            STRIPE_CHECKOUT_ENABLED: envData.REACT_APP_STRIPE_CHECKOUT_ENABLED,
            SIDEBAR_PRODUCT_IMAGES_ENABLED: envData.REACT_APP_SIDEBAR_PRODUCT_IMAGES_ENABLED,
            CART_ENABLED: envData.REACT_APP_CART_ENABLED,
            SHOWPRICE: envData.REACT_APP_SHOWPRICE,
            SAMPLE_PRODUCT_SLIDER: envData.REACT_APP_SAMPLE_PRODUCT_SLIDER
        }
        let updated_at = Date.now().toString()
        const formData = {
            org_id,
            name,
            mask,
            logo,
            greenmask,
            theme_color: themeColor,
            font,
            favicon,
            landingpage,
            products,
            secrets,
            updated_at
        };
        addOrganisation(formData);
        setLoading(true)
        try{
            const succeeded = await fetchAddtoOrganisation(formData, navigate);
        if (succeeded.success) {
            console.log('Organisation added successfully:', formData);
        } else {
            console.error('Failed to add organisation:', succeeded.error);
        }
        }
        catch(e){
            console.log("error creating organisation:",e)
        }
        finally{
            const envContent = [
                `REACT_APP_ORGANISATION_ID="${org_id}"`,
                ...Object.entries(envData).map(([key, value]) => `${key}="${value}"`)
            ].join('\n');
            const blob = new Blob([envContent], { type: 'text/plain;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${formData.name}.txt`;
            link.click();
            URL.revokeObjectURL(url);
            setLoading(false)
        }
        window.location.reload();
    };
    const addProduct = () => {
        const newProduct = {
            name: '',
            description: '',
            default_color: '',
            sizes: [],
            mask: '',
            greenmask: '',
            colors: {}
        };
        setProducts([...products, newProduct]);
    };
    const removeProduct = (index) => {
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);
        const updatedUploadedImages = [...uploadedImage];
        updatedUploadedImages.splice(index, 1);
        setUploadedImage(updatedUploadedImages);
    };
    const addColor = (index) => {
        const updatedProducts = [...products];
        const newColorKey = Date.now().toString(); // Unique key for each color
        const newColor = {
            name: '',
            asset: {
                front: '',
                back: ''
            },
            clip: '',
            color_map: ''
        };
        updatedProducts[index].colors[newColorKey] = newColor;
        setProducts(updatedProducts);
    };
    const removeColor = (index, colorKey) => {
        const updatedProducts = [...products];
        delete updatedProducts[index].colors[colorKey];
        setProducts(updatedProducts);
    };
    const validatePage = () => {
        switch (page) {
            case 1:
                return name && mask && logo && themeColor && font && favicon;
            case 2:
                return landingpage.every(sample => sample.name && sample.asset);
            case 3:
                return Object.keys(products).length > 0 && products.every(product => 
                    product.name && 
                    product.description && 
                    product.sizes && 
                    product.defaultProduct &&
                    product.default_color && 
                    product.mask &&
                    product.colors && 
                    product.position &&
                    Object.keys(product.colors).length > 0 &&
                    Object.values(product.colors).every(color => 
                        color.name && 
                        color.asset && 
                        color.asset.front
                    )
                );
            case 4:
                return (
                    envData.REACT_APP_STRIPE_CHECKOUT_ENABLED !== undefined &&
                    envData.REACT_APP_SIDEBAR_PRODUCT_IMAGES_ENABLED !== undefined &&
                    envData.REACT_APP_CART_ENABLED !== undefined &&
                    envData.REACT_APP_SHOWPRICE !== undefined &&
                    envData.REACT_APP_SAMPLE_PRODUCT_SLIDER !== undefined
                    )
            case 5:
                return (
                products.every(product => product.dimensions) && 
                uploadedImage.length === products.length
                );
            default:
                return false;
        }
    };
    const toggleColorSection = (e,colorKey) => {
        e.preventDefault()
        setExpandedColors((prev) => ({
            ...prev,
            [colorKey]: !prev[colorKey],
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEnvData({
            ...envData,
            [name]: value === 'true'
        });
    };
    const handleGreenRemoveImage = () => {
        setGreenMask(null);
    };
    const handleRemoveLogoImage = () => {
        setLogo(null);
    }
    const handleRemoveMaskImage = () => {
        setMask(null);
    }
    const handleRemoveFaviconImage = () => {
        setFavicon(null);
    }
    const handleRemoveSampleAsset = (index,type) => {
        // Update the landingpage state by setting the asset of the specified sample to null or an empty string
        if(type == 'asset'){
            setlandingpage((prevLandingPage) =>
                prevLandingPage.map((sample, i) =>
                    i === index ? { ...sample, asset: null } : sample
                )
            );
        } else {
            setlandingpage((prevLandingPage) =>
                prevLandingPage.map((sample, i) =>
                    i === index ? { ...sample, asset_back: null } : sample
                )
            );
        }
    };
    
    const renderPage = () => {
        switch (page) {
            case 1:
                return (
                    <div className="organisation-details">
                        <div className="form-group form-group-new">
                            <label>Organisation Name:</label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                            <Tooltip content="Enter the name of the organisation.">
                                <button className="info-button">i</button>
                            </Tooltip>
                            <span className="required-text">* Required</span><br/>
                        </div>
                        <div className="form-group form-group-new">
                            <label style={{ marginRight: '10px' }}>Mask (Upload PNG/JPEG Image):</label>
                            {mask && (
                                <div style={{ position: 'relative', marginRight: '10px' }}>
                                    <img src={mask} alt="Mask Preview"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '5px',
                                            objectFit: 'cover',
                                            border: '2px solid green',
                                        }}/>
                                    <button onClick={handleRemoveMaskImage} className='img-close-btn'>&times;</button>
                                </div>
                            )}
                            <input type="file" onChange={handleMaskUpload} style={{ display: 'none' }} id="mask-upload" disabled={!!mask}/>
                            <label htmlFor="mask-upload" className="upload-button"
                                style={{
                                    cursor: mask ? 'not-allowed' : 'pointer',
                                    padding: '10px 20px',
                                    backgroundColor: mask ? '#ccc' : 'green',
                                    color: 'white',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                }}>
                                {mask ? 'Image Uploaded' : 'Upload Image'}
                            </label>
                            <Tooltip content="This mask is for the landing page.">
                                <button className="info-button" style={{ marginLeft: '5px', marginRight: '10px' }}>i</button>
                            </Tooltip>
                            <span style={{  marginLeft: '10px' }} className="required-text">* Required</span>
                        </div>
                        <div className="form-group form-group-new">
                            <label style={{ marginRight: '10px' }}>Logo (Upload PNG/JPEG Image):</label>
                            {logo && (
                                <div style={{ position: 'relative', marginRight: '10px' }}>
                                    <img src={logo} alt="Logo Preview"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '5px',
                                            objectFit: 'cover',
                                            border: '2px solid green',
                                        }}/>
                                    <button onClick={handleRemoveLogoImage} className='img-close-btn'>&times;</button>
                                </div>
                            )}
                            <input type="file" onChange={handleLogoUpload} style={{ display: 'none' }} id="logo-upload" disabled={!!logo}/>
                            <label htmlFor="logo-upload" className="upload-button"
                                style={{
                                    cursor: logo ? 'not-allowed' : 'pointer',
                                    padding: '10px 20px',
                                    backgroundColor: logo ? '#ccc' : 'green',
                                    color: 'white',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                }}>
                                {logo ? 'Image Uploaded' : 'Upload Image'}
                            </label>
                            <Tooltip content="Logo of the Organisation.">
                                <button className="info-button" style={{ marginLeft: '5px', marginRight: '10px' }}>i</button>
                            </Tooltip>
                            <span style={{  marginLeft: '10px' }} className="required-text">* Required</span>
                        </div>
                        <div className="form-group form-group-new">
                            <label style={{ marginRight: '10px' }}>Green Mask (Upload PNG/JPEG Image):</label>
                            {greenmask && (
                                <div style={{ position: 'relative', marginRight: '10px' }}>
                                    <img src={greenmask} alt="Green Mask Preview"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '5px',
                                            objectFit: 'cover',
                                            border: '2px solid green',
                                        }}/>
                                    <button onClick={handleGreenRemoveImage} className='img-close-btn'>&times;</button>
                                </div>
                            )}
                            <input type="file" onChange={handleGreenMaskUpload} style={{ display: 'none' }} id="green-mask-upload" disabled={!!greenmask}/>
                            <label htmlFor="green-mask-upload" className="upload-button"
                                style={{
                                    cursor: greenmask ? 'not-allowed' : 'pointer',
                                    padding: '10px 20px',
                                    backgroundColor: greenmask ? '#ccc' : 'green',
                                    color: 'white',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                }}>
                                {greenmask ? 'Image Uploaded' : 'Upload Image'}
                            </label>
                            <Tooltip content="Green Mask for the organisation.">
                                <button className="info-button" style={{ marginLeft: '5px', marginRight: '10px' }}>i</button>
                            </Tooltip>
                            <span style={{ color: 'green', marginLeft: '10px' }} className="required-text">* Optional</span>
                        </div>
                        <div className="form-group form-group-new">
                            <label>Theme Color:</label>
                            <input type="color" value={themeColor} onChange={(e) => setThemeColor(e.target.value)} required />
                            <Tooltip content="This will be used as a primary colour.">
                                <button className="info-button">i</button>
                            </Tooltip>
                            <span className="required-text">* Required</span><br/>
                        </div>
                        <div className="form-group form-group-new">
                            <label>Font:</label>
                            <input type="text" value={font} onChange={(e) => setFont(e.target.value)} required />
                            <Tooltip content="For primary Font.">
                                <button className="info-button">i</button>
                            </Tooltip>
                            <span className="required-text">* Required</span><br/>
                        </div>
                        <div className="form-group form-group-new">
                            <label style={{ marginRight: '10px' }}>Favicon (Upload PNG/JPEG Image):</label>
                            {favicon && (
                                <div style={{ position: 'relative', marginRight: '10px' }}>
                                    <img src={favicon} alt="Favicon Preview"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '5px',
                                            objectFit: 'cover',
                                            border: '2px solid green',
                                        }}/>
                                    <button onClick={handleRemoveFaviconImage} className='img-close-btn'>&times;</button>
                                </div>
                            )}
                            <input type="file" onChange={handleFaviconUpload} style={{ display: 'none' }} id="favicon-upload" disabled={!!favicon}/>
                            <label htmlFor="favicon-upload" className="upload-button"
                                style={{
                                    cursor: favicon ? 'not-allowed' : 'pointer',
                                    padding: '10px 20px',
                                    backgroundColor: favicon ? '#ccc' : 'green',
                                    color: 'white',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                }}>
                                {favicon ? 'Image Uploaded' : 'Upload Image'}
                            </label>
                            <span style={{  marginLeft: '10px' }} className="required-text">* Required</span>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="landing-page-samples">
                        <h2>Landing Page Sample Products:</h2>
                        <div className={`sample-form-parent`}>
                            {landingpage.map((sample, index) => (
                                <div className="sample-form-group form-group" key={index}>
                                    <label>Sample Product Name {index + 1}:</label>
                                    <Tooltip content="Choose a type for sample product.">
                                        <button className="info-button">i</button>
                                    </Tooltip>
                                    <select value={sample.name} onChange={(e) => handleLandingPageSampleChange(index, 'name', e.target.value)} required>
                                        <option value="">Select a product</option>
                                        {productsList.map((option, idx) => (
                                            <option key={idx} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="required-text">* Required</span><br />
                                    <label>Front Asset (Upload PNG/JPEG Image):</label>
                                    <Tooltip content="Upload Image for sample product.">
                                        <button className="info-button">i</button>
                                    </Tooltip>
                                    <div className='form-group-new flex-row-important' style={{border:'none',marginTop:'10px'}}>
                                    {sample.asset && (
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                            <img
                                                src={sample.asset}
                                                alt={`Sample Asset Preview ${index + 1}`}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '5px',
                                                    objectFit: 'cover',
                                                    border: '2px solid green',
                                                }}
                                            />
                                            <button onClick={() => handleRemoveSampleAsset(index, 'asset')} className="img-close-btn">
                                                &times;
                                            </button>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        onChange={(e) => handleLandingPageSampleAssetChange(index, e)}
                                        style={{ display: 'none' }}
                                        id={`sample-asset-upload-${index}`}
                                        disabled={!!sample.asset}
                                        required
                                    />
                                    
                                    <label
                                        htmlFor={`sample-asset-upload-${index}`}
                                        className="upload-button"
                                        style={{
                                            cursor: sample.asset ? 'not-allowed' : 'pointer',
                                            padding: '10px 20px',
                                            backgroundColor: sample.asset ? '#ccc' : 'green',
                                            color: 'white',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            width: '80%'
                                        }}
                                    >
                                        {sample.asset ? 'Image Uploaded' : 'Upload Image'}
                                    </label>
                                    </div>
                                    <span className="required-text">* Required</span><br />
                                    <label>Back Asset (Upload PNG/JPEG Image):</label>
                                    <Tooltip content="Upload Image for sample product.">
                                        <button className="info-button">i</button>
                                    </Tooltip>
                                    <div className='form-group-new flex-row-important' style={{border:'none',marginTop:'10px'}}>
                                    {sample.asset_back && (
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                            <img
                                                src={sample.asset_back}
                                                alt={`Sample Asset Preview ${index + 1}`}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '5px',
                                                    objectFit: 'cover',
                                                    border: '2px solid green',
                                                }}
                                            />
                                            <button onClick={() => handleRemoveSampleAsset(index, 'asset_back')} className="img-close-btn">
                                                &times;
                                            </button>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        onChange={(e) => handleLandingPageSampleAssetbackChange(index, e)}
                                        style={{ display: 'none' }}
                                        id={`sample-asset-back-upload-${index}`}
                                        disabled={!!sample.asset_back}
                                        required
                                    />
                                    <label
                                        htmlFor={`sample-asset-back-upload-${index}`}
                                        className="upload-button"
                                        style={{
                                            cursor: sample.asset_back ? 'not-allowed' : 'pointer',
                                            padding: '10px 20px',
                                            backgroundColor: sample.asset_back ? '#ccc' : 'green',
                                            color: 'white',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            width: '80%'
                                        }}
                                    >
                                        {sample.asset_back ? 'Image Uploaded' : 'Upload Image'}
                                    </label>
                                    </div>
                                    <span className="required-text" style={{color: 'green'}}>* Optional</span><br />
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="products-form">
                        <h2>Products:</h2>
                        <div className={`products-card-grid`}>
                        {products.map((product, index) => (
                            <div className="product-group" key={index}>
                                <div className="form-group form-group-new">
                                    <label>Product {index + 1}:</label>
                                    <select value={product.name} onChange={(e) => handleProductChange(index, 'name', e.target.value)} required>
                                        <option value="">Select a product</option>
                                        {productsList.map((option, idx) => (
                                            <option key={idx} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="required-text">* Required</span><br/>
                                </div>
                                <div className="form-group form-group-new">
                                    <label>Description:</label>
                                    <input type="text" value={product.description} onChange={(e) => handleProductChange(index, 'description', e.target.value)} required />
                                    <Tooltip content="Used on product list dropdown.">
                                        <button className="info-button">i</button>
                                    </Tooltip>
                                    <span className="required-text">* Required</span><br/>
                                </div>
                                <div className="form-group form-group-new">
                                    <label>Default Color:</label>
                                    <input type="color" value={product.default_color} onChange={(e) => handleProductChange(index, 'default_color', e.target.value)} required />
                                    <span className="required-text">* Required</span><br/>
                                </div>
                                <div className="form-group form-group-new">
                                    <label>Product position:</label>
                                    <label style={{display:"flex", gap:"5px"}}>
                                        <input
                                            type="radio"
                                            name={"product.position."+index}
                                            value="front"
                                            checked={product.position === 'front'}
                                            onChange={(e) => handleProductChange(index, 'position', e.target.value)}
                                        />
                                        Front
                                    </label>
                                    <label style={{display:"flex", gap:"5px"}}>
                                        <input
                                            type="radio"
                                            name={"product.position."+index}
                                            value="back"
                                            checked={product.position === 'back'}
                                            onChange={(e) => handleProductChange(index, 'position', e.target.value)}
                                        />
                                        Back
                                    </label>
                                    <span className="required-text">* Required</span><br/>
                                </div>
                                <div className="form-group form-group-new">
                                    <label>Sizes:</label>
                                    <div className="checkbox-group">
                                        {['XS', 'S', 'M', 'L', 'XL', '2XL'].map(size => (
                                            <label key={size}>
                                                <input
                                                    type="checkbox"
                                                    checked={product.sizes.includes(size)}
                                                    onChange={() => handleSizeChange(index, size)}
                                                    required
                                                />
                                                {size}
                                            </label>
                                        ))}
                                    </div>
                                    <span className="required-text">* Required</span><br/>
                                </div>
                                <div className="form-group form-group-new" style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px' }}>Mask (Upload PNG/JPEG Files):</label>
                                    {productMasks[index] && (
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                            <img
                                                src={productMasks[index]}
                                                alt="Product Mask Preview"
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '5px',
                                                    objectFit: 'cover',
                                                    border: '2px solid green',
                                                }}
                                            />
                                            <button
                                                onClick={() => handleRemoveMask(index)} className='img-close-btn'>
                                                &times;
                                            </button>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        onChange={(e) => handleProductMaskChange(index,e)}
                                        style={{ display: 'none' }}
                                        id={`product-mask-upload-${index}`}
                                        disabled={!!productMasks[index]}
                                        required
                                    />
                                    <label
                                        htmlFor={`product-mask-upload-${index}`}
                                        className="upload-button"
                                        style={{
                                            cursor: productMasks[index] ? 'not-allowed' : 'pointer',
                                            padding: '10px 20px',
                                            backgroundColor: productMasks[index] ? '#ccc' : 'green',
                                            color: 'white',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {productMasks[index] ? 'Image Uploaded' : 'Upload Image'}
                                    </label>
                                    <Tooltip content="This mask will be used on zoomer (Upload cropped LOGO).">
                                        <button className="info-button" style={{ marginLeft: '5px', marginRight: '10px' }}>i</button>
                                    </Tooltip>
                                    <span style={{ color: 'red', marginLeft: '10px' }} className="required-text">* Required</span>
                                </div>
                                <div className="form-group form-group-new" style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px' }}>Green Mask:</label>
                                    {productGreenMasks[index] && (
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                            <img
                                                src={productGreenMasks[index]}
                                                alt="Product Green Mask Preview"
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '5px',
                                                    objectFit: 'cover',
                                                    border: '2px solid green',
                                                }}
                                            />
                                            <button
                                                onClick={() => handleRemoveGreenMask(index)} className='img-close-btn'>
                                                &times;
                                            </button>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        onChange={(e) => handleProductGreenMaskChange(index,e)}
                                        style={{ display: 'none' }}
                                        id={`product-greenmask-upload-${index}`}
                                        disabled={!!productGreenMasks[index]}
                                        required
                                    />
                                    <label
                                        htmlFor={`product-greenmask-upload-${index}`}
                                        className="upload-button"
                                        style={{
                                            cursor: productGreenMasks[index] ? 'not-allowed' : 'pointer',
                                            padding: '10px 20px',
                                            backgroundColor: productGreenMasks[index] ? '#ccc' : 'green',
                                            color: 'white',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        
                                        {productGreenMasks[index] ? 'Image Uploaded' : 'Upload Image'}
                                    </label>
                                    <Tooltip content="This mask will be used on zoomer (Upload cropped LOGO).">
                                        <button className="info-button" style={{ marginLeft: '5px', marginRight: '10px' }}>i</button>
                                    </Tooltip>
                                    <span style={{ color: 'green', marginLeft: '10px' }} className="required-text">* Optional</span>
                                </div>
                                <div className="form-group form-group-new" style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '10px' }}>Product pic for zoomer (Upload PNG/JPEG Files):</label>
                                {productPics[index] && (
                                    <div style={{ position: 'relative', marginRight: '10px' }}>
                                        <img
                                            src={productPics[index]}
                                            alt="Product Pic Preview"
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '5px',
                                                objectFit: 'cover',
                                                border: '2px solid green',
                                            }}
                                        />
                                        <button onClick={() => handleRemovePic(index)} className='img-close-btn'>
                                            &times;
                                        </button>
                                    </div>
                                )}
                                <input
                                    type="file"
                                    onChange={(e) => handleProductDefaultImage(index,e)}
                                    style={{ display: 'none' }}
                                    id={`product-pic-upload-${index}`}
                                    disabled={!!productPics[index]}
                                    required
                                />
                                <label
                                    htmlFor={`product-pic-upload-${index}`}
                                    className="upload-button"
                                    style={{
                                        cursor: productPics[index] ? 'not-allowed' : 'pointer',
                                        padding: '10px 20px',
                                        backgroundColor: productPics[index] ? '#ccc' : 'green',
                                        color: 'white',
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                    }}
                                >
   
                                    {productPics[index] ? 'Image Uploaded' : 'Upload Image'}
                                </label>
                                <Tooltip content="This picture will be used on the final stage of onboarding (used to adjust zoomer dimension).">
                                    <button className="info-button" style={{ marginLeft: '10px', marginRight: '10px' }}>i</button>
                                </Tooltip>
                                <span style={{ color: 'red', marginLeft: '10px' }} className="required-text">* Required</span>
                            </div>
                                <div className="form-group">
                                    <h4>Colors:</h4>
                                    {Object.keys(product.colors).map((colorKey, colorIndex) => (
                                        <div className="color-group" key={colorKey}>
                                            <div className="color-header" onClick={(e) => toggleColorSection(e, colorKey)}>
                                                <h5>Color {colorIndex + 1}</h5>
                                                <button className="toggle-btn">
                                                    {expandedColors[colorKey] ? '▲' : '▼'}
                                                </button>
                                            </div>
                                            {expandedColors[colorKey] && (
                                                <div className="color-details">
                                                    <div className="form-group form-group-new">
                                                        <label>Color Name:</label>
                                                        <input
                                                            type="text"
                                                            value={product.colors[colorKey].name}
                                                            onChange={(e) => handleProductColorNameChange(index, colorKey, e.target.value)}
                                                            required
                                                        />
                                                        <span className="required-text">* Required</span><br />
                                                    </div>
                                                    <div className="form-group form-group-new">
                                                        <label>Color:</label>
                                                        <input
                                                            type="color"
                                                            value={product.colors[colorKey].color_map}
                                                            onChange={(e) => handleProductColorChange(index, colorKey, e.target.value)}
                                                            required
                                                        />
                                                        <span className="required-text">* Required</span><br />
                                                    </div>
                                                    <div className="form-group form-group-new">
                                                        <label>Color Asset (Front):</label>
                                                        <Tooltip content="Front Image of product">
                                                            <button className="info-button">i</button>
                                                        </Tooltip>
                                                        {colorAssets[colorKey]?.front && (
                                                            <div style={{ position: 'relative', marginRight: '10px' }}>
                                                                <img
                                                                    src={colorAssets[colorKey].front}
                                                                    alt="Front Asset Preview"
                                                                    style={{
                                                                        width: '50px',
                                                                        height: '50px',
                                                                        borderRadius: '5px',
                                                                        objectFit: 'cover',
                                                                        border: '2px solid green',
                                                                    }}
                                                                />
                                                                <button onClick={(e) => handleRemoveAsset(index,colorKey, 'front',e)} className='img-close-btn'>
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        )}
                                                        <input
                                                            type="file"
                                                            onChange={(e) => handleProductColorAssetChange(index, colorKey, 'front', e)}
                                                            style={{ display: 'none' }}
                                                            id={`color-asset-front-upload-${colorKey}`}
                                                            disabled={!!colorAssets[colorKey]?.front}
                                                            required
                                                        />
                                                        <label
                                                            htmlFor={`color-asset-front-upload-${colorKey}`}
                                                            className="upload-button"
                                                            style={{
                                                                cursor: colorAssets[colorKey]?.front ? 'not-allowed' : 'pointer',
                                                                padding: '10px 20px',
                                                                backgroundColor: colorAssets[colorKey]?.front ? '#ccc' : 'green',
                                                                color: 'white',
                                                                borderRadius: '5px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {colorAssets[colorKey]?.front ? 'Image Uploaded' : 'Upload Image'}
                                                        </label>
                                                        <span className="required-text">* Required</span><br />
                                                    </div>
                                                    <div className="form-group form-group-new">
                                                        <label>Color Asset (Back):</label>
                                                        <Tooltip content="Back Image of product">
                                                            <button className="info-button">i</button>
                                                        </Tooltip>
                                                        {colorAssets[colorKey]?.back && (
                                                            <div style={{ position: 'relative', marginRight: '10px' }}>
                                                                <img
                                                                    src={colorAssets[colorKey].back}
                                                                    alt="Back Asset Preview"
                                                                    style={{
                                                                        width: '50px',
                                                                        height: '50px',
                                                                        borderRadius: '5px',
                                                                        objectFit: 'cover',
                                                                        border: '2px solid green',
                                                                    }}
                                                                />
                                                                <button onClick={(e) => handleRemoveAsset(index,colorKey, 'back',e)} className='img-close-btn'>
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        )}
                                                        <input
                                                            type="file"
                                                            onChange={(e) => handleProductColorAssetChange(index, colorKey, 'back', e)}
                                                            style={{ display: 'none' }}
                                                            id={`color-asset-back-upload-${colorKey}`}
                                                            disabled={!!colorAssets[colorKey]?.back}
                                                        />
                                                        <label
                                                            htmlFor={`color-asset-back-upload-${colorKey}`}
                                                            className="upload-button"
                                                            style={{
                                                                cursor: colorAssets[colorKey]?.back ? 'not-allowed' : 'pointer',
                                                                padding: '10px 20px',
                                                                backgroundColor: colorAssets[colorKey]?.back ? '#ccc' : 'green',
                                                                color: 'white',
                                                                borderRadius: '5px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {colorAssets[colorKey]?.back ? 'Image Uploaded' : 'Upload Image'}
                                                        </label>
                                                        <span className="required-text" style={{ color: 'green' }}>* Optional</span><br />
                                                    </div>
                                                    <button className="remove-colour-btn" type="button" onClick={() => removeColor(index, colorKey)}>Remove Color</button>
                                                    <hr />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <button className="add-btn" type="button" onClick={() => addColor(index)}>Add Color</button>
                                </div>
                                <button className="remove-colour-btn" type="button" onClick={() => removeProduct(index)}>Remove Product</button>
                                <hr />
                            </div>
                        ))}
                    </div>
                        <button className="add-btn" type="button" onClick={addProduct}>Add Product</button>
                    </div>
                );
            case 4:
                return (
                        <div className='organization-site'>
            <h1>Organization Site Details:</h1>
                <div className='org-data'>
                    <label>
                    <div className='org-data-heading'>
                        Organisation using Stripe Checkout:
                    </div>
                        <RadioButtonGroup
                            name="REACT_APP_STRIPE_CHECKOUT_ENABLED"
                            value={envData.REACT_APP_STRIPE_CHECKOUT_ENABLED}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className='org-data'>
                    <label>
                    <div className='org-data-heading'>
                        Organisation using sidebar product catelog:
                    </div>
                        <RadioButtonGroup
                            name="REACT_APP_SIDEBAR_PRODUCT_IMAGES_ENABLED"
                            value={envData.REACT_APP_SIDEBAR_PRODUCT_IMAGES_ENABLED}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className='org-data'>
                    <label>
                    <div className='org-data-heading'>
                        Organisation using CART:
                    </div>
                        <RadioButtonGroup
                            name="REACT_APP_CART_ENABLED"
                            value={envData.REACT_APP_CART_ENABLED}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className='org-data'>
                    <label>
                    <div className='org-data-heading'>
                        Organisation using product prices:
                    </div>
                        <RadioButtonGroup
                            name="REACT_APP_SHOWPRICE"
                            value={envData.REACT_APP_SHOWPRICE}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className='org-data'>
                    <label>
                        <div className='org-data-heading'>
                        Organisation using sample product slider:
                        </div>
                        <RadioButtonGroup
                            name="REACT_APP_SAMPLE_PRODUCT_SLIDER"
                            value={envData.REACT_APP_SAMPLE_PRODUCT_SLIDER}
                            onChange={handleChange}
                        />
                    </label>
                </div>
        </div>
        );
            case 5: 
                return (
                    <div>
                        <h2>Adjust zoomer</h2>
                        {uploadedImage && uploadedImage.map((image, index) => (
                            <ApparelPreview key={index} uploadedImage={image} maskImage={products[index].mask}
                            onUpdateDimensions={(dimensions) => handleUpdateDimensions(index, dimensions)}/>
                        ))}
                        <button className="add-btn" type="submit" disabled={!validatePage()} onClick={handleSubmit} style={{marginTop:'10%'}}>Submit</button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
        {loading ? 
        <div className="loading-container">
            <div className="loading-spinner"></div>
            <p className="loading-text">Creating New organization, please wait...</p>
        </div> 
    : 
            <form onSubmit={handleSubmit}>
                {renderPage()}
                <div className="pagination-buttons">
                    {page > 1 && <button type="button" className="previous-btn-org" onClick={() => setPage(page - 1)}>Back</button>}
                    {page < 5 && <button type="button" className="next-btn-org" onClick={() => setPage(page + 1)} 
                    disabled={!validatePage()}
                    >Next</button>}
                </div>
            </form>
        }
        </>
    );
};


export default OrganisationForm;
